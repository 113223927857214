const { CHAIN_IDS, GET_PRICE_TYPES, ESTIMATED_APY_TYPES, SUSHI_POOLS_IDS } = require('../constants')

const addresses = require('./addresses.json')

const profitSharingCut8Percent = '0.92'
const profitSharingCut10Percent = '0.90'
const profitSharingCut15Percent = '0.85'
const profitSharingCut30Percent = '0.7'

module.exports = {
  FARM: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/farm.svg'],
    tokenAddress: addresses.FARM,
    tokenNames: ['FARM'],
    platform: ['Harvest'],
    tags: ['Beginners'],
    decimals: '18',
    vaultAddress: null,
    newDetails: true,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.FARM] },
  },
  WETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/eth.svg'],
    apyIconUrls: ['./icons/comp.svg'],
    apyTokenSymbols: ['COMP'],
    tokenNames: ['ETH'],
    platform: ['Compound V3'],
    tags: ['Beginners'],
    tokenAddress: addresses.WETH,
    decimals: '18',
    vaultAddress: addresses.V2.WETH.NewVault,
    strategyAddress: addresses.V2.WETH.NewStrategy,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WETH] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.COMPOUND_V3,
        params: [
          addresses.V2.WETH.Underlying,
          addresses.V2.WETH.Market,
          profitSharingCut15Percent,
          CHAIN_IDS.ETH_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'WETH'],
  },
  reactor_ETH: {
    chain: CHAIN_IDS.ZKSYNC,
    apyIconUrls: ['./icons/rf.svg'],
    apyTokenSymbols: ['RF'],
    logoUrl: ['./icons/eth.svg'],
    tokenNames: ['ETH'],
    platform: ['ReactorFusion'],
    tags: ['Beginners'],
    tokenAddress: addresses.ZKSYNC.V2.reactor_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.reactor_ETH.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.reactor_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.REACTORFUSION_FOLD,
        params: [
          addresses.ZKSYNC.V2.reactor_ETH.Underlying,
          addresses.ZKSYNC.V2.reactor_ETH.cToken,
          addresses.ZKSYNC.V2.reactor_ETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
  },
  reactor_USDCe: {
    chain: CHAIN_IDS.ZKSYNC,
    apyIconUrls: ['./icons/rf.svg'],
    apyTokenSymbols: ['RF'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC.e'],
    platform: ['ReactorFusion'],
    tags: ['Beginners'],
    tokenAddress: addresses.ZKSYNC.V2.reactor_USDCe.Underlying,
    decimals: '6',
    vaultAddress: addresses.ZKSYNC.V2.reactor_USDCe.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.reactor_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.REACTORFUSION_FOLD,
        params: [
          addresses.ZKSYNC.V2.reactor_USDCe.Underlying,
          addresses.ZKSYNC.V2.reactor_USDCe.cToken,
          addresses.ZKSYNC.V2.reactor_USDCe.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
  },
  reactor_USDT: {
    chain: CHAIN_IDS.ZKSYNC,
    apyIconUrls: ['./icons/rf.svg'],
    apyTokenSymbols: ['RF'],
    logoUrl: ['./icons/usdt.svg'],
    tokenNames: ['USDT'],
    platform: ['ReactorFusion'],
    tags: ['Beginners'],
    tokenAddress: addresses.ZKSYNC.V2.reactor_USDT.Underlying,
    decimals: '6',
    vaultAddress: addresses.ZKSYNC.V2.reactor_USDT.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.reactor_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tether'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.REACTORFUSION_FOLD,
        params: [
          addresses.ZKSYNC.V2.reactor_USDT.Underlying,
          addresses.ZKSYNC.V2.reactor_USDT.cToken,
          addresses.ZKSYNC.V2.reactor_USDT.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
  },
  reactor_WBTC: {
    chain: CHAIN_IDS.ZKSYNC,
    apyIconUrls: ['./icons/rf.svg'],
    apyTokenSymbols: ['RF'],
    logoUrl: ['./icons/wbtc.svg'],
    tokenNames: ['WBTC'],
    platform: ['ReactorFusion'],
    tags: ['Beginners'],
    tokenAddress: addresses.ZKSYNC.V2.reactor_WBTC.Underlying,
    decimals: '8',
    vaultAddress: addresses.ZKSYNC.V2.reactor_WBTC.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.reactor_WBTC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-bitcoin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.REACTORFUSION_FOLD,
        params: [
          addresses.ZKSYNC.V2.reactor_WBTC.Underlying,
          addresses.ZKSYNC.V2.reactor_WBTC.cToken,
          addresses.ZKSYNC.V2.reactor_WBTC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
  },
  zkswap_ZK_ETH: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/zk.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/zf.svg'],
    apyTokenSymbols: ['ZF'],
    tokenNames: ['ZK', 'ETH'],
    platform: ['zkSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.ZKSYNC.V2.zkswap_ZK_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.zkswap_ZK_ETH.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.zkswap_ZK_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ZKSYNC.V2.zkswap_ZK_ETH.Underlying, 'ZK', 'WETH_zksync'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.ZKSWAP,
        params: [addresses.ZKSYNC.V2.zkswap_ZK_ETH.Underlying, 30, profitSharingCut10Percent],
      },
    ],
  },
  zkswap_ETH_USDCe: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/eth.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/zf.svg'],
    apyTokenSymbols: ['ZF'],
    tokenNames: ['ETH', 'USDC.e'],
    platform: ['zkSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.ZKSYNC.V2.zkswap_ETH_USDCe.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.zkswap_ETH_USDCe.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.zkswap_ETH_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ZKSYNC.V2.zkswap_ETH_USDCe.Underlying, 'WETH_zksync', 'USDCe_zksync'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.ZKSWAP,
        params: [addresses.ZKSYNC.V2.zkswap_ETH_USDCe.Underlying, 2, profitSharingCut10Percent],
      },
    ],
  },
  zkswap_LONG_ETH: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/long.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/zf.svg'],
    apyTokenSymbols: ['ZF'],
    tokenNames: ['LONG', 'ETH'],
    platform: ['zkSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.ZKSYNC.V2.zkswap_LONG_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.zkswap_LONG_ETH.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.zkswap_LONG_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ZKSYNC.V2.zkswap_LONG_ETH.Underlying, 'LONG', 'WETH_zksync'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.ZKSWAP,
        params: [addresses.ZKSYNC.V2.zkswap_LONG_ETH.Underlying, 21, profitSharingCut10Percent],
      },
    ],
  },
  zkswap_USDCe_USDT: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/usdc.svg', './icons/usdt.svg'],
    apyIconUrls: ['./icons/zf.svg'],
    apyTokenSymbols: ['ZF'],
    tokenNames: ['USDC.e', 'USDT'],
    platform: ['zkSwap'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ZKSYNC.V2.zkswap_USDCe_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.zkswap_USDCe_USDT.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.zkswap_USDCe_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ZKSYNC.V2.zkswap_USDCe_USDT.Underlying, 'USDCe_zksync', 'USDT_zksync'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.ZKSWAP,
        params: [addresses.ZKSYNC.V2.zkswap_USDCe_USDT.Underlying, 3, profitSharingCut10Percent],
      },
    ],
  },
  zkswap_WBTC_ETH: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/wbtc.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/zf.svg'],
    apyTokenSymbols: ['ZF'],
    tokenNames: ['WBTC', 'ETH'],
    platform: ['zkSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.ZKSYNC.V2.zkswap_WBTC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.zkswap_WBTC_ETH.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.zkswap_WBTC_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ZKSYNC.V2.zkswap_WBTC_ETH.Underlying, 'WBTC_zksync', 'WETH_zksync'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.ZKSWAP,
        params: [addresses.ZKSYNC.V2.zkswap_WBTC_ETH.Underlying, 4, profitSharingCut10Percent],
      },
    ],
  },
  zkswap_wstETH_ETH: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/steth.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/zf.svg'],
    apyTokenSymbols: ['ZF'],
    tokenNames: ['stETH', 'ETH'],
    platform: ['zkSwap'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ZKSYNC.V2.zkswap_wstETH_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.zkswap_wstETH_ETH.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.zkswap_wstETH_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ZKSYNC.V2.zkswap_wstETH_ETH.Underlying, 'wstETH_zksync', 'WETH_zksync'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.ZKSWAP,
        params: [addresses.ZKSYNC.V2.zkswap_wstETH_ETH.Underlying, 19, profitSharingCut10Percent],
      },
    ],
  },
  zkswap_ZF_ETH: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/zf.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/zf.svg'],
    apyTokenSymbols: ['ZF'],
    tokenNames: ['ZF', 'ETH'],
    platform: ['zkSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.ZKSYNC.V2.zkswap_ZF_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ZKSYNC.V2.zkswap_ZF_ETH.NewVault,
    strategyAddress: addresses.ZKSYNC.V2.zkswap_ZF_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ZKSYNC.V2.zkswap_ZF_ETH.Underlying, 'ZF', 'WETH_zksync'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.ZKSWAP,
        params: [addresses.ZKSYNC.V2.zkswap_ZF_ETH.Underlying, 1, profitSharingCut10Percent],
      },
    ],
  },
  RF: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/rf.svg'],
    tokenAddress: addresses.ZKSYNC.RF,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['reactorfusion'],
    },
  },
  ZF: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/zf.svg'],
    tokenAddress: addresses.ZKSYNC.ZF,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['zkswap-finance'],
    },
  },
  ZK: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/zk.svg'],
    tokenAddress: addresses.ZKSYNC.ZK,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['zksync'],
    },
  },
  LONG: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/long.svg'],
    tokenAddress: addresses.ZKSYNC.LONG,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['long-3'],
    },
  },
  WETH_zksync: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/eth.svg'],
    tokenAddress: addresses.ZKSYNC.WETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WETH] },
  },
  USDCe_zksync: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/usdc.svg'],
    tokenAddress: addresses.ZKSYNC.USDCe,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
  },
  USDT_zksync: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/usdt.svg'],
    tokenAddress: addresses.ZKSYNC.USDT,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tether'],
    },
  },
  WBTC_zksync: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/wbtc.svg'],
    tokenAddress: addresses.ZKSYNC.WBTC,
    decimals: '8',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-bitcoin'],
    },
  },
  wstETH_zksync: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/wsteth.svg'],
    tokenAddress: addresses.ZKSYNC.wstETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
  },
  iFARM_zksync: {
    chain: CHAIN_IDS.ZKSYNC,
    logoUrl: ['./icons/ifarm.svg'],
    tokenAddress: addresses.ZKSYNC.iFARM,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['ifarm'],
    },
  },
  penpie_rsETH2706: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg'],
    apyTokenSymbols: ['PENDLE'],
    logoUrl: ['./icons/rsethlp.svg'],
    tokenNames: ['rsETH-LP (27-JUN-24)'],
    platform: ['Penpie'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_rsETH2706.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_rsETH2706.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_rsETH2706.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_rsETH2706.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_rsETH2706.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  penpie_eETH2706: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg'],
    apyTokenSymbols: ['PENDLE'],
    logoUrl: ['./icons/eethlp.svg'],
    tokenNames: ['eETH-LP (27-JUN-24)'],
    platform: ['Penpie'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_eETH2706.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_eETH2706.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_eETH2706.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_eETH2706.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_eETH2706.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  penpie_ezETH2706: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg'],
    apyTokenSymbols: ['PENDLE'],
    logoUrl: ['./icons/ezethlp.svg'],
    tokenNames: ['ezETH-LP (27-JUN-24)'],
    platform: ['Penpie'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_ezETH2706.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_ezETH2706.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_ezETH2706.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_ezETH2706.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_ezETH2706.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  penpie_wstETH2706: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg'],
    apyTokenSymbols: ['PENDLE'],
    logoUrl: ['./icons/wstethlp.svg'],
    tokenNames: ['wstETH-LP (27-JUN-24)'],
    platform: ['Penpie'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_wstETH2706.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_wstETH2706.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_wstETH2706.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_wstETH2706.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_wstETH2706.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  IFARM_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/ifarm.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['iFARM'],
    platform: ['Harvest'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.iFARM.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.iFARM.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.iFARM.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.F_TOKEN,
      params: [addresses.iFARM, '18'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
  },
  GENOME: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/genome.svg'],
    tokenAddress: addresses.BASE.GENOME,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.AERODROME_PAIR,
      params: [addresses.BASE.GENOME, addresses.BASE.WETH, 6],
    },
  },
  aerodrome_GENOME_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/genome.svg', './icons/eth.svg'],
    tokenNames: ['GENOME', 'ETH'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'DeSci'],
    tokenAddress: addresses.BASE.V2.aerodrome_GENOME_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_GENOME_ETH.NewVault,
    strategyAddress: addresses.BASE.V2.aerodrome_GENOME_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_GENOME_ETH.Underlying, 'GENOME', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_GENOME_ETH.Underlying,
          addresses.BASE.V2.aerodrome_GENOME_ETH.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  penpie_gDAI2609: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg'],
    apyTokenSymbols: ['PENDLE'],
    logoUrl: ['./icons/gdai.svg'],
    tokenNames: ['gDAI-LP (26-SEP-24)'],
    platform: ['Penpie'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_gDAI2609.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_gDAI2609.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_gDAI2609.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_gDAI2609.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_gDAI2609.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  penpie_aUSDC2706: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg'],
    apyTokenSymbols: ['PENDLE'],
    logoUrl: ['./icons/ausdc.svg'],
    tokenNames: ['aUSDC-LP (27-JUN-24)'],
    platform: ['Penpie'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_aUSDC2706.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_aUSDC2706.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_aUSDC2706.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_aUSDC2706.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_aUSDC2706.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  penpie_mPENDLE2609: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg'],
    apyTokenSymbols: ['PENDLE'],
    logoUrl: ['./icons/mpendle.png'],
    tokenNames: ['mPENDLE-LP (26-SEP-24)'],
    platform: ['Penpie'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_mPENDLE2609.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_mPENDLE2609.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_mPENDLE2609.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_mPENDLE2609.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_mPENDLE2609.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  GB_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/gb.svg'],
    tokenAddress: addresses.BASE.GB,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['grand-base'],
    },
  },
  OVN_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/ovn.svg'],
    tokenAddress: addresses.BASE.OVN,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['overnight-finance'],
    },
  },
  STAR_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/star.svg'],
    tokenAddress: addresses.BASE.STAR,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['preon-star'],
    },
  },
  aerodrome_GB_ETH: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/gb.svg', './icons/eth.svg'],
    tokenNames: ['GB', 'ETH'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_GB_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_GB_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_GB_ETH.Underlying, 'GB_base', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_GB_ETH.Underlying,
          addresses.BASE.V2.aerodrome_GB_ETH.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_OVN_USDp: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/ovn.svg', './icons/usdplus.svg'],
    tokenNames: ['OVN', 'USD+'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_OVN_USDp.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_OVN_USDp.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_OVN_USDp.Underlying, 'OVN_base', 'USD+_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_OVN_USDp.Underlying,
          addresses.BASE.V2.aerodrome_OVN_USDp.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_USDC_AERO: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/aero.svg', './icons/usdc.svg'],
    tokenNames: ['AERO', 'USDC'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_USDC_AERO.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_USDC_AERO.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_USDC_AERO.Underlying, 'AERO', 'USDC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_USDC_AERO.Underlying,
          addresses.BASE.V2.aerodrome_USDC_AERO.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_WELL_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/well.svg', './icons/eth.svg'],
    tokenNames: ['WELL', 'ETH'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_WELL_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_WELL_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_WELL_ETH.Underlying, 'WELL_old', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_WELL_ETH.Underlying,
          addresses.BASE.V2.aerodrome_WELL_ETH.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_STAR_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/star.svg', './icons/usdc.svg'],
    tokenNames: ['STAR', 'USDC'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.aerodrome_STAR_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_STAR_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_STAR_USDC.Underlying, 'STAR_base', 'USDC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_STAR_USDC.Underlying,
          addresses.BASE.V2.aerodrome_STAR_USDC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_USDC_USDbC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/usdc.svg', './icons/usdbc.svg'],
    tokenNames: ['USDC', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.aerodrome_USDC_USDbC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_USDC_USDbC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_USDC_USDbC.Underlying, 'USDC_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_USDC_USDbC.Underlying,
          addresses.BASE.V2.aerodrome_USDC_USDbC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_SEAM_USDbC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/seam.svg', './icons/usdbc.svg'],
    tokenNames: ['SEAM', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_SEAM_USDbC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_SEAM_USDbC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_SEAM_USDbC.Underlying, 'SEAM', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_SEAM_USDbC.Underlying,
          addresses.BASE.V2.aerodrome_SEAM_USDbC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  gammaMerkl_QS_tBTC_ETH: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/tbtc.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Narrow',
    tokenNames: ['tBTC', 'ETH'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.gammaMerkl_QS_tBTC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.gammaMerkl_QS_tBTC_ETH.NewVault,
    strategyAddress: addresses.MATIC.V2.gammaMerkl_QS_tBTC_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.gammaMerkl_QS_tBTC_ETH.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.gammaMerkl_QS_tBTC_ETH.Underlying,
          addresses.MATIC.V2.gammaMerkl_QS_tBTC_ETH.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  gammaMerkl_QS_WBTC_ETH_w: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/wbtc.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Wide',
    tokenNames: ['WBTC', 'ETH'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.gammaMerkl_QS_WBTC_ETH_w.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.gammaMerkl_QS_WBTC_ETH_w.NewVault,
    strategyAddress: addresses.MATIC.V2.gammaMerkl_QS_WBTC_ETH_w.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.gammaMerkl_QS_WBTC_ETH_w.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.gammaMerkl_QS_WBTC_ETH_w.Underlying,
          addresses.MATIC.V2.gammaMerkl_QS_WBTC_ETH_w.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  crvUSD_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/crvusd.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.crvUSD,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['crvusd'],
    },
  },
  USDCoin_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/crvusd.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.USDCoin,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
  },
  fETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/feth.png'],
    tokenAddress: addresses.ARBITRUM_ONE.fETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['f-x-protocol-fractional-eth'],
    },
  },
  xETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/xeth.png'],
    tokenAddress: addresses.ARBITRUM_ONE.xETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['f-x-protocol-leveraged-eth'],
    },
  },
  frxETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/frxeth.png'],
    tokenAddress: addresses.ARBITRUM_ONE.frxETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['frax-ether'],
    },
  },
  curve_crvUSD_FRAX: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/curve.svg', './icons/arb.svg'],
    apyTokenSymbols: ['CRV', 'ARB'],
    logoUrl: ['./icons/crvusd.svg', './icons/frax.svg'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['crvUSD', 'FRAX'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_FRAX.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_FRAX.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_FRAX.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_FRAX.Underlying,
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_FRAX.Underlying,
        '18',
        ['FRAX_arbitrum', 'crvUSD_arbitrum'],
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX_L2,
        params: [
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_FRAX.Underlying,
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_FRAX.PoolId,
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV', 'ARB'],
  },
  curve_crvUSD_USDC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/curve.svg', './icons/arb.svg'],
    apyTokenSymbols: ['CRV', 'ARB'],
    logoUrl: ['./icons/crvusd.svg', './icons/usdc.svg'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['crvUSD', 'USDC'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDC_arbitrum.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDC_arbitrum.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDC_arbitrum.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDC_arbitrum.Underlying,
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDC_arbitrum.Underlying,
        '18',
        ['crvUSD_arbitrum', 'USDCoin_arbitrum'],
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX_L2,
        params: [
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDC_arbitrum.Underlying,
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDC_arbitrum.PoolId,
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV', 'ARB'],
  },
  curve_crvUSD_USDCe: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/curve.svg', './icons/arb.svg'],
    apyTokenSymbols: ['CRV', 'ARB'],
    logoUrl: ['./icons/crvusd.svg', './icons/usdc.svg'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['crvUSD', 'USDC.e'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDCe.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDCe.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDCe.Underlying,
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDCe.Underlying,
        '18',
        ['crvUSD_arbitrum', 'USDC_arbitrum'],
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX_L2,
        params: [
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDCe.Underlying,
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDCe.PoolId,
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV', 'ARB'],
  },
  curve_crvUSD_USDT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/curve.svg', './icons/arb.svg'],
    apyTokenSymbols: ['CRV', 'ARB'],
    logoUrl: ['./icons/crvusd.svg', './icons/usdt.svg'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['crvUSD', 'USDT'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDT.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDT.Underlying,
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDT.Underlying,
        '18',
        ['crvUSD_arbitrum', 'USDT_arbitrum'],
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX_L2,
        params: [
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDT.Underlying,
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_USDT.PoolId,
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV', 'ARB'],
  },
  curve_crvUSD_WBTC_WETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/curve.svg', './icons/arb.svg'],
    apyTokenSymbols: ['CRV', 'ARB'],
    logoUrl: ['./icons/crvusd.svg', './icons/wbtc.svg', './icons/eth.svg'],
    platform: ['Convex Finance'],
    tags: ['Advanced'],
    tokenNames: ['crvUSD', 'WBTC', 'ETH'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_WBTC_WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_WBTC_WETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.curve_crvUSD_WBTC_WETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_WBTC_WETH.Underlying,
        addresses.ARBITRUM_ONE.V2.curve_crvUSD_WBTC_WETH.Underlying,
        '18',
        ['crvUSD_arbitrum', 'WBTC_arbitrum', 'ETH'],
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX_L2,
        params: [
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_WBTC_WETH.Underlying,
          addresses.ARBITRUM_ONE.V2.curve_crvUSD_WBTC_WETH.PoolId,
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV', 'ARB'],
  },
  curve_FRAX_USDCe: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/curve.svg'],
    apyTokenSymbols: ['CRV'],
    logoUrl: ['./icons/frax.svg', './icons/usdc.svg'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['FRAX', 'USDC.e'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.curve_FRAX_USDCe.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.curve_FRAX_USDCe.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.curve_FRAX_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.ARBITRUM_ONE.V2.curve_FRAX_USDCe.Underlying,
        addresses.ARBITRUM_ONE.V2.curve_FRAX_USDCe.Underlying,
        '18',
        ['FRAX_arbitrum', 'USDC_arbitrum'],
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX_L2,
        params: [
          addresses.ARBITRUM_ONE.V2.curve_FRAX_USDCe.Underlying,
          addresses.ARBITRUM_ONE.V2.curve_FRAX_USDCe.PoolId,
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV'],
  },
  USDC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/usdc.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.USDC,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
  },
  curve_USDCe_USDT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/curve.svg', './icons/arb.svg'],
    apyTokenSymbols: ['CRV', 'ARB'],
    logoUrl: ['./icons/usdc.svg', './icons/usdt.svg'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['USDC.e', 'USDT'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.curve_USDCe_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.curve_USDCe_USDT.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.curve_USDCe_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.ARBITRUM_ONE.V2.curve_USDCe_USDT.Underlying,
        addresses.ARBITRUM_ONE.V2.curve_USDCe_USDT.Underlying,
        '18',
        ['USDC_arbitrum', 'USDT_arbitrum'],
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX_L2,
        params: [
          addresses.ARBITRUM_ONE.V2.curve_USDCe_USDT.Underlying,
          addresses.ARBITRUM_ONE.V2.curve_USDCe_USDT.PoolId,
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV', 'ARB'],
  },
  penpie_eETH2504: {
    inactive: true,
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/pendle.svg', './icons/arb.svg'],
    apyTokenSymbols: ['PENDLE', 'ARB'],
    logoUrl: ['./icons/eethlp.svg'],
    tokenNames: ['eETH-LP (25-APR-24)'],
    platform: ['Penpie'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.penpie_eETH2504.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.penpie_eETH2504.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.penpie_eETH2504.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.PENDLE_MARKET,
      params: [addresses.ARBITRUM_ONE.V2.penpie_eETH2504.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.PENPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.penpie_eETH2504.Underlying,
          CHAIN_IDS.ARBITRUM_ONE,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'PENLDE'],
  },
  aave_EURS: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    apyIconUrls: ['./icons/aave.svg'],
    apyTokenSymbols: ['AAVE'],
    logoUrl: ['./icons/eurs.svg'],
    tokenNames: ['EURS'],
    platform: ['Aave'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.MATIC.V2.aave_EURS.Underlying,
    decimals: '2',
    vaultAddress: addresses.MATIC.V2.aave_EURS.NewVault,
    strategyAddress: addresses.MATIC.V2.aave_EURS.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['stasis-eurs'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AAVE_SUPPLY,
        params: [
          addresses.MATIC.V2.aave_EURS.Underlying,
          addresses.MATIC.V2.aave_EURS.AavePool,
          profitSharingCut8Percent,
          CHAIN_IDS.POLYGON_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM'],
  },
  aave_USDC: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    apyIconUrls: ['./icons/aave.svg'],
    apyTokenSymbols: ['AAVE'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC'],
    platform: ['Aave'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.MATIC.V2.aave_USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.MATIC.V2.aave_USDC.NewVault,
    strategyAddress: addresses.MATIC.V2.aave_USDC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AAVE_SUPPLY,
        params: [
          addresses.MATIC.V2.aave_USDC.Underlying,
          addresses.MATIC.V2.aave_USDC.AavePool,
          profitSharingCut8Percent,
          CHAIN_IDS.POLYGON_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM'],
  },
  aave_USDCe: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    apyIconUrls: ['./icons/aave.svg'],
    apyTokenSymbols: ['AAVE'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDCe'],
    platform: ['Aave'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.MATIC.V2.aave_USDCe.Underlying,
    decimals: '6',
    vaultAddress: addresses.MATIC.V2.aave_USDCe.NewVault,
    strategyAddress: addresses.MATIC.V2.aave_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AAVE_SUPPLY,
        params: [
          addresses.MATIC.V2.aave_USDCe.Underlying,
          addresses.MATIC.V2.aave_USDCe.AavePool,
          profitSharingCut8Percent,
          CHAIN_IDS.POLYGON_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM'],
  },
  aave_USDT: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    apyIconUrls: ['./icons/aave.svg'],
    apyTokenSymbols: ['AAVE'],
    logoUrl: ['./icons/usdt.svg'],
    tokenNames: ['USDT'],
    platform: ['Aave'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.MATIC.V2.aave_USDT.Underlying,
    decimals: '6',
    vaultAddress: addresses.MATIC.V2.aave_USDT.NewVault,
    strategyAddress: addresses.MATIC.V2.aave_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tether'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AAVE_SUPPLY,
        params: [
          addresses.MATIC.V2.aave_USDT.Underlying,
          addresses.MATIC.V2.aave_USDT.AavePool,
          profitSharingCut8Percent,
          CHAIN_IDS.POLYGON_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM'],
  },
  rsETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/rseth.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.rsETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['kelp-dao-restaked-eth'],
    },
  },
  camelotV3_rsETH_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/rseth.svg', './icons/eth.svg'],
    tokenNames: ['rsETH', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Pegged',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_rsETH_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_rsETH_ETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.camelotV3_rsETH_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_rsETH_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_rsETH_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_rsETH_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_rsETH_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  gammaMerkl_QS_ETH_USDC: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/eth.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Narrow',
    tokenNames: ['ETH', 'USDC'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.gammaMerkl_QS_ETH_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.gammaMerkl_QS_ETH_USDC.NewVault,
    strategyAddress: addresses.MATIC.V2.gammaMerkl_QS_ETH_USDC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.gammaMerkl_QS_ETH_USDC.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.gammaMerkl_QS_ETH_USDC.Underlying,
          addresses.MATIC.V2.gammaMerkl_QS_ETH_USDC.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  gammaMerkl_QS_ETH_USDT: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/eth.svg', './icons/usdt.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Narrow',
    tokenNames: ['ETH', 'USDT'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.gammaMerkl_QS_ETH_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.gammaMerkl_QS_ETH_USDT.NewVault,
    strategyAddress: addresses.MATIC.V2.gammaMerkl_QS_ETH_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.gammaMerkl_QS_ETH_USDT.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.gammaMerkl_QS_ETH_USDT.Underlying,
          addresses.MATIC.V2.gammaMerkl_QS_ETH_USDT.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  gammaMerkl_QS_MATIC_USDT: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/matic.svg', './icons/usdt.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Narrow',
    tokenNames: ['MATIC', 'USDT'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.gammaMerkl_QS_MATIC_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.gammaMerkl_QS_MATIC_USDT.NewVault,
    strategyAddress: addresses.MATIC.V2.gammaMerkl_QS_MATIC_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.gammaMerkl_QS_MATIC_USDT.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.gammaMerkl_QS_MATIC_USDT.Underlying,
          addresses.MATIC.V2.gammaMerkl_QS_MATIC_USDT.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  lodestarHodl_MAGIC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/magic.svg'],
    tokenNames: ['MAGIC'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['magic'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_MAGIC.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_PENDLE: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/pendle.svg'],
    tokenNames: ['PENDLE'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['pendle'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_PENDLE.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_MAGIC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/magic.svg'],
    tokenNames: ['MAGIC'],
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_MAGIC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_MAGIC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_MAGIC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['magic'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_MAGIC.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_MAGIC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_MAGIC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_PENDLE: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/pendle.svg'],
    tokenNames: ['PENDLE'],
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_PENDLE.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_PENDLE.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_PENDLE.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['pendle'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_PENDLE.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_PENDLE.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_PENDLE.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  camelotV3_MAGIC_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/magic.svg', './icons/eth.svg'],
    tokenNames: ['MAGIC', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_MAGIC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_MAGIC_ETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.camelotV3_MAGIC_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_MAGIC_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_MAGIC_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_MAGIC_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_MAGIC_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_PENDLE_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/pendle.svg', './icons/eth.svg'],
    tokenNames: ['PENDLE', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_PENDLE_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_PENDLE_ETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.camelotV3_PENDLE_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_PENDLE_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_PENDLE_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_PENDLE_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_PENDLE_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_RDNT_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/rdnt.svg', './icons/eth.svg'],
    tokenNames: ['RDNT', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_RDNT_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_RDNT_ETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.camelotV3_RDNT_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_RDNT_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_RDNT_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_RDNT_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_RDNT_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_USDC_USDT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/usdc.svg', './icons/usdt.svg'],
    tokenNames: ['USDC', 'USDT'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Stable',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_USDC_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_USDC_USDT.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.camelotV3_USDC_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_USDC_USDT.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_USDC_USDT.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_USDC_USDT.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_USDC_USDT.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_wstETH_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/steth.svg', './icons/eth.svg'],
    tokenNames: ['stETH', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Pegged',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_wstETH_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_wstETH_ETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.camelotV3_wstETH_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_wstETH_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_wstETH_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_wstETH_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_wstETH_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  seamless_wstETH: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/seam.svg'],
    apyTokenSymbols: ['esSEAM'],
    logoUrl: ['./icons/steth.svg'],
    tokenNames: ['wstETH'],
    platform: ['Seamless'],
    tags: ['Beginners', 'LSD'],
    tokenAddress: addresses.BASE.V2.seamless_wstETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.seamless_wstETH.NewVault,
    strategyAddress: addresses.BASE.V2.seamless_wstETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SEAMLESS_FOLD,
        params: [
          addresses.BASE.V2.seamless_wstETH.Underlying,
          addresses.BASE.V2.seamless_wstETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'esSEAM'],
  },
  RDP: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/rdp.png'],
    tokenAddress: addresses.ARBITRUM_ONE.RDP,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['radpie'],
    },
  },
  plvGLP: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.plvGLP,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.MANUAL,
      params: ['1.5'],
    },
  },
  radpie_ARB: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg'],
    apyTokenSymbols: ['RDNT'],
    logoUrl: ['./icons/arb.svg'],
    tokenNames: ['rARB'],
    platform: ['Radpie'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_ARB.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_ARB.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_ARB.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['arbitrum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_ARB.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_ARB.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT'],
  },
  radpie_DAI: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg'],
    apyTokenSymbols: ['RDNT'],
    logoUrl: ['./icons/dai.svg'],
    tokenNames: ['rDAI'],
    platform: ['Radpie'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_DAI.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_DAI.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_DAI.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_DAI.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_DAI.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT'],
  },
  radpie_WETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg'],
    apyTokenSymbols: ['RDNT'],
    logoUrl: ['./icons/eth.svg'],
    tokenNames: ['rETH'],
    platform: ['Radpie'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_WETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_WETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_WETH.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_WETH.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT'],
  },
  radpie_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg', './icons/arb.svg'],
    apyTokenSymbols: ['RDNT', 'ARB'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['rUSDC'],
    platform: ['Radpie'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_USDC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_USDC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_USDC.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_USDC.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT', 'ARB'],
  },
  radpie_USDCe: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg', './icons/arb.svg'],
    apyTokenSymbols: ['RDNT', 'ARB'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['rUSDC.e'],
    platform: ['Radpie'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_USDCe.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_USDCe.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_USDCe.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_USDCe.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT', 'ARB'],
  },
  radpie_USDT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg', './icons/arb.svg'],
    apyTokenSymbols: ['RDNT', 'ARB'],
    logoUrl: ['./icons/usdt.svg'],
    tokenNames: ['rUSDT'],
    platform: ['Radpie'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_USDT.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_USDT.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_USDT.NewStrategy,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.USDT] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_USDT.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_USDT.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT', 'ARB'],
  },
  radpie_WBTC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg', './icons/rdp.png', './icons/arb.svg'],
    apyTokenSymbols: ['RDNT', 'RDP', 'ARB'],
    logoUrl: ['./icons/wbtc.svg'],
    tokenNames: ['rWBTC'],
    platform: ['Radpie'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_WBTC.Underlying,
    decimals: '8',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_WBTC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_WBTC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-bitcoin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_WBTC.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_WBTC.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT'],
  },
  radpie_wstETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/rdnt.svg'],
    apyTokenSymbols: ['RDNT'],
    logoUrl: ['./icons/steth.svg'],
    tokenNames: ['rwstETH'],
    platform: ['Radpie'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.radpie_wstETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.radpie_wstETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.radpie_wstETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.RADPIE,
        params: [
          addresses.ARBITRUM_ONE.V2.radpie_wstETH.PoolId,
          addresses.ARBITRUM_ONE.V2.radpie_wstETH.Rewarder,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'RDNT'],
  },
  SEAM: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/seam.svg'],
    tokenAddress: addresses.BASE.SEAM,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['seamless-protocol'],
    },
  },
  esSEAM: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/seam.svg'],
    tokenAddress: addresses.BASE.esSEAM,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['seamless-protocol'],
    },
  },
  seamless_cbETH: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/seam.svg'],
    apyTokenSymbols: ['esSEAM'],
    logoUrl: ['./icons/cbeth.svg'],
    tokenNames: ['cbETH'],
    platform: ['Seamless'],
    tags: ['Beginners', 'LSD'],
    tokenAddress: addresses.BASE.V2.seamless_cbETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.seamless_cbETH.NewVault,
    strategyAddress: addresses.BASE.V2.seamless_cbETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['coinbase-wrapped-staked-eth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SEAMLESS_FOLD,
        params: [
          addresses.BASE.V2.seamless_cbETH.Underlying,
          addresses.BASE.V2.seamless_cbETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'esSEAM'],
  },
  seamless_DAI: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/seam.svg'],
    apyTokenSymbols: ['esSEAM'],
    logoUrl: ['./icons/dai.svg'],
    tokenNames: ['DAI'],
    platform: ['Seamless'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.BASE.V2.seamless_DAI.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.seamless_DAI.NewVault,
    strategyAddress: addresses.BASE.V2.seamless_DAI.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SEAMLESS_FOLD,
        params: [
          addresses.BASE.V2.seamless_DAI.Underlying,
          addresses.BASE.V2.seamless_DAI.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'esSEAM'],
  },
  seamless_ETH: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/seam.svg'],
    apyTokenSymbols: ['esSEAM'],
    logoUrl: ['./icons/eth.svg'],
    tokenNames: ['ETH'],
    platform: ['Seamless'],
    tags: ['Beginners'],
    tokenAddress: addresses.BASE.V2.seamless_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.seamless_ETH.NewVault,
    strategyAddress: addresses.BASE.V2.seamless_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SEAMLESS_FOLD,
        params: [
          addresses.BASE.V2.seamless_ETH.Underlying,
          addresses.BASE.V2.seamless_ETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'esSEAM'],
  },
  seamless_USDbC: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/seam.svg'],
    apyTokenSymbols: ['esSEAM'],
    logoUrl: ['./icons/usdbc.svg'],
    tokenNames: ['USDbC'],
    platform: ['Seamless'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.BASE.V2.seamless_USDbC.Underlying,
    decimals: '6',
    vaultAddress: addresses.BASE.V2.seamless_USDbC.NewVault,
    strategyAddress: addresses.BASE.V2.seamless_USDbC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SEAMLESS_FOLD,
        params: [
          addresses.BASE.V2.seamless_USDbC.Underlying,
          addresses.BASE.V2.seamless_USDbC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'esSEAM'],
  },
  seamless_USDC: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/seam.svg'],
    apyTokenSymbols: ['esSEAM'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC'],
    platform: ['Seamless'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.BASE.V2.seamless_USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.BASE.V2.seamless_USDC.NewVault,
    strategyAddress: addresses.BASE.V2.seamless_USDC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SEAMLESS_FOLD,
        params: [
          addresses.BASE.V2.seamless_USDC.Underlying,
          addresses.BASE.V2.seamless_USDC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'esSEAM'],
  },
  lodestarHodl_ARB: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/arb.svg'],
    tokenNames: ['ARB'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['arbitrum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ARB.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_DAI: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/dai.svg'],
    tokenNames: ['DAI'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_DAI.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/eth.svg'],
    tokenNames: ['ETH'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_ETH.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_FRAX: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/frax.svg'],
    tokenNames: ['FRAX'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['frax'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_FRAX.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_GMX: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/gmx.svg'],
    tokenNames: ['GMX'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['gmx'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_GMX.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_USDCe: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDCe'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDCe.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_USDT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/usdt.svg'],
    tokenNames: ['USDT'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tether'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDT.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_WBTC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/wbtc.svg'],
    tokenNames: ['WBTC'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.Underlying,
    decimals: '8',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-bitcoin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_WBTC.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestarHodl_wstETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/steth.svg'],
    tokenNames: ['wstETH'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_wstETH.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  fLODE: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_LODE.NewVault,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.F_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.lodestar_LODE.NewVault, '18', CHAIN_IDS.ARBITRUM_ONE],
    },
  },
  lodestarHodl_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/arb.svg', './icons/lode.svg'],
    apyTokenSymbols: ['ARB', 'LODE'],
    apyDescriptionOverride: [
      'Auto compounding <u>ARB</u>',
      'Auto harvested <u>LODE</u> claimable as <u>fLODE</u>',
    ],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC'],
    subLabel: 'HODL',
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_COMPOUNDING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD_HODL_HODLING,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestarHodl_USDC.NewStrategy,
          'lodestar_LODE',
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_LODE: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/eth.svg'],
    apyTokenSymbols: ['WETH'],
    logoUrl: ['./icons/lode.svg'],
    tokenNames: ['LODE'],
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_LODE.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_LODE.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_LODE.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['lodestar'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_STAKE,
        params: [profitSharingCut10Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WETH'],
  },
  lodestar_ARB: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/arb.svg'],
    tokenNames: ['ARB'],
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_ARB.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_ARB.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_ARB.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['arbitrum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_ARB.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_ARB.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_ARB.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_FRAX: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/frax.svg'],
    tokenNames: ['FRAX'],
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_FRAX.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_FRAX.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_FRAX.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['frax'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_FRAX.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_FRAX.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_FRAX.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_GMX: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/gmx.svg'],
    tokenNames: ['GMX'],
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_GMX.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_GMX.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_GMX.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['gmx'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_GMX.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_GMX.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_GMX.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_wstETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/steth.svg'],
    tokenNames: ['wstETH'],
    platform: ['Lodestar'],
    tags: ['Beginners', 'LSD'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_wstETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_wstETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_wstETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_wstETH.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_wstETH.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_wstETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  MGP: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/mgp.png'],
    tokenAddress: addresses.ARBITRUM_ONE.MGP,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['magpie'],
    },
  },
  WOM: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/wom.png'],
    tokenAddress: addresses.ARBITRUM_ONE.WOM,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wombat-exchange'],
    },
  },
  magpie_ETH_frxeth: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eth.svg'],
    apyIconUrls: ['./icons/mgp.png', './icons/wom.png'],
    apyTokenSymbols: ['MGP', 'WOM'],
    platform: ['Magpie'],
    tags: ['Advanced'],
    tokenNames: ['ETH-LP'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.magpie_ETH_frxeth.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.magpie_ETH_frxeth.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.magpie_ETH_frxeth.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MAGPIE,
        params: [addresses.ARBITRUM_ONE.V2.magpie_ETH_frxeth.Underlying, profitSharingCut10Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'MGP', 'WOM'],
  },
  moonwell_wstETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/well.svg'],
    apyTokenSymbols: ['WELL'],
    logoUrl: ['./icons/wsteth.svg'],
    tokenNames: ['wstETH'],
    platform: ['Moonwell'],
    tags: ['Beginners', 'LSD'],
    tokenAddress: addresses.BASE.V2.moonwell_wstETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.moonwell_wstETH.NewVault,
    strategyAddress: addresses.BASE.V2.moonwell_wstETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MOONWELL_FOLD,
        params: [
          addresses.BASE.V2.moonwell_wstETH.Underlying,
          addresses.BASE.V2.moonwell_wstETH.mToken,
          73,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WELL'],
  },
  moonwell_rETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/well.svg'],
    apyTokenSymbols: ['WELL'],
    logoUrl: ['./icons/reth.svg'],
    tokenNames: ['rETH'],
    platform: ['Moonwell'],
    tags: ['Beginners', 'LSD'],
    tokenAddress: addresses.BASE.V2.moonwell_rETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.moonwell_rETH.NewVault,
    strategyAddress: addresses.BASE.V2.moonwell_rETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['rocket-pool-eth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MOONWELL_FOLD,
        params: [
          addresses.BASE.V2.moonwell_rETH.Underlying,
          addresses.BASE.V2.moonwell_rETH.mToken,
          73,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WELL'],
  },
  lodestar_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/eth.svg'],
    tokenNames: ['ETH'],
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_ETH.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_ETH.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['ethereum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_ETH.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_ETH.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_ETH.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_DAI: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/dai.svg'],
    tokenNames: ['DAI'],
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_DAI.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_DAI.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_DAI.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_DAI.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_DAI.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_DAI.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC'],
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_USDC.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_USDC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_USDC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_USDCe: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDCe'],
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDCe.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDCe.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDCe.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_USDCe.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_USDCe.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_USDCe.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_USDT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/usdt.svg'],
    tokenNames: ['USDT'],
    platform: ['Lodestar'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDT.Underlying,
    decimals: '6',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDT.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_USDT.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tether'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_USDT.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_USDT.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_USDT.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  lodestar_WBTC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/lode.svg', './icons/arb.svg'],
    apyTokenSymbols: ['LODE', 'ARB'],
    logoUrl: ['./icons/wbtc.svg'],
    tokenNames: ['WBTC'],
    platform: ['Lodestar'],
    tags: ['Beginners'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.lodestar_WBTC.Underlying,
    decimals: '8',
    vaultAddress: addresses.ARBITRUM_ONE.V2.lodestar_WBTC.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.lodestar_WBTC.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-bitcoin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LODESTAR_FOLD,
        params: [
          addresses.ARBITRUM_ONE.V2.lodestar_WBTC.Underlying,
          addresses.ARBITRUM_ONE.V2.lodestar_WBTC.cToken,
          addresses.ARBITRUM_ONE.V2.lodestar_WBTC.NewStrategy,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LODE', 'ARB'],
  },
  camelotV3_ARB_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/arb.svg', './icons/eth.svg'],
    tokenNames: ['ARB', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ARB_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ARB_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_ARB_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_ARB_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_ARB_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_ARB_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_DAI_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/dai.svg', './icons/usdc.svg'],
    tokenNames: ['DAI', 'USDC'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Stable',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_DAI_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_DAI_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_DAI_USDC.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_DAI_USDC.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_DAI_USDC.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_DAI_USDC.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_GMX_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/gmx.svg', './icons/eth.svg'],
    tokenNames: ['GMX', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_GMX_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_GMX_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_GMX_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_GMX_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_GMX_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_GMX_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_GRAIL_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/grail.svg', './icons/eth.svg'],
    tokenNames: ['GRAIL', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_GRAIL_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_GRAIL_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_GRAIL_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_GRAIL_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_GRAIL_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_GRAIL_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_LINK_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/link.svg', './icons/eth.svg'],
    tokenNames: ['LINK', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_LINK_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_LINK_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_LINK_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_LINK_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_LINK_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_LINK_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_WBTC_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/wbtc.svg', './icons/eth.svg'],
    tokenNames: ['WBTC', 'ETH'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_WBTC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_WBTC_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_WBTC_ETH.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_WBTC_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_WBTC_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_WBTC_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelotV3_ARB_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/arb.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    tokenNames: ['ARB', 'USDC'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ARB_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ARB_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_ARB_USDC.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_ARB_USDC.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_ARB_USDC.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_ARB_USDC.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL'],
  },
  camelotV3_ETH_USDT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eth.svg', './icons/usdt.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    tokenNames: ['ETH', 'USDT'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDT.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDT.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDT.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDT.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDT.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL'],
  },
  camelotV3_ETH_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eth.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    tokenNames: ['ETH', 'USDC'],
    subLabel: 'Narrow',
    platform: ['Camelot V3', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDC.Underlying, CHAIN_IDS.ARBITRUM_ONE],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDC.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDC.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelotV3_ETH_USDC.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL'],
  },
  quickGamma_wstETH_ETH: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/steth.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Pegged Price',
    tokenNames: ['stETH', 'ETH'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.MATIC.V2.quickGamma_wstETH_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickGamma_wstETH_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.quickGamma_wstETH_ETH.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.quickGamma_wstETH_ETH.Underlying,
          addresses.MATIC.V2.quickGamma_wstETH_ETH.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  quickGamma_WBTC_USDC_narrow: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/wbtc.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Narrow',
    tokenNames: ['WBTC', 'USDC.e'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.quickGamma_WBTC_USDC_narrow.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickGamma_WBTC_USDC_narrow.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [
        addresses.MATIC.V2.quickGamma_WBTC_USDC_narrow.Underlying,
        CHAIN_IDS.POLYGON_MAINNET,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.quickGamma_WBTC_USDC_narrow.Underlying,
          addresses.MATIC.V2.quickGamma_WBTC_USDC_narrow.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  quickGamma_WBTC_ETH_narrow: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/wbtc.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/quick.svg'],
    apyTokenSymbols: ['dQUICK'],
    subLabel: 'Narrow',
    tokenNames: ['WBTC', 'ETH'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.quickGamma_WBTC_ETH_narrow.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickGamma_WBTC_ETH_narrow.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.quickGamma_WBTC_ETH_narrow.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.quickGamma_WBTC_ETH_narrow.Underlying,
          addresses.MATIC.V2.quickGamma_WBTC_ETH_narrow.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK'],
  },
  axlWBTC: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/axlwbtc.svg'],
    tokenAddress: addresses.BASE.axlWBTC,
    decimals: '8',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['axlwbtc'],
    },
  },
  moonwell_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/well.svg'],
    apyTokenSymbols: ['WELL'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC'],
    platform: ['Moonwell'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.BASE.V2.moonwell_USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.BASE.V2.moonwell_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MOONWELL_FOLD,
        params: [
          addresses.BASE.V2.moonwell_USDC.Underlying,
          addresses.BASE.V2.moonwell_USDC.mToken,
          78,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WELL'],
  },
  baseswap_axlUSDC_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/axlusdc.svg', './icons/eth.svg'],
    tokenNames: ['axlUSDC', 'ETH'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.baseswap_axlUSDC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_axlUSDC_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_axlUSDC_ETH.Underlying, 'axlUSDC', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_axlUSDC_ETH.NewStrategy,
          addresses.BASE.V2.baseswap_axlUSDC_ETH.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_axlWBTC_USDbC: {
    inactive: true,
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/axlwbtc.svg', './icons/usdbc.svg'],
    tokenNames: ['axlWBTC', 'USDbC'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.baseswap_axlWBTC_USDbC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_axlWBTC_USDbC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_axlWBTC_USDbC.Underlying, 'axlWBTC', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_axlWBTC_USDbC.NewStrategy,
          addresses.BASE.V2.baseswap_axlWBTC_USDbC.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_ETH_DAI: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/eth.svg', './icons/dai.svg'],
    tokenNames: ['ETH', 'DAI'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.baseswap_ETH_DAI.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_ETH_DAI.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_ETH_DAI.Underlying, 'DAI_base', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_ETH_DAI.NewStrategy,
          addresses.BASE.V2.baseswap_ETH_DAI.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_USDbC_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/usdbc.svg', './icons/usdc.svg'],
    tokenNames: ['USDbC', 'USDC'],
    platform: ['BaseSwap'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.baseswap_USDbC_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_USDbC_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_USDbC_USDC.Underlying, 'USDC_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_USDbC_USDC.NewStrategy,
          addresses.BASE.V2.baseswap_USDbC_USDC.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_USDp_USDbC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/usdplus.svg', './icons/usdbc.svg'],
    tokenNames: ['USD+', 'USDbC'],
    platform: ['BaseSwap'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.baseswap_USDp_USDbC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_USDp_USDbC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_USDp_USDbC.Underlying, 'USD+_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_USDp_USDbC.NewStrategy,
          addresses.BASE.V2.baseswap_USDp_USDbC.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_USDC_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/eth.svg', './icons/usdc.svg'],
    tokenNames: ['ETH', 'USDC'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.baseswap_USDC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_USDC_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_USDC_ETH.Underlying, 'USDC_base', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_USDC_ETH.NewStrategy,
          addresses.BASE.V2.baseswap_USDC_ETH.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  fxBSX: {
    chain: CHAIN_IDS.BASE,
    logoUrl: [''],
    tokenAddress: addresses.BASE.V2.xBSX.NewVault,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.F_TOKEN,
      params: [addresses.BASE.V2.xBSX.NewVault, '18', CHAIN_IDS.BASE],
    },
  },
  xBSX: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/xbsx.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['xBSX'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.xBSX.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.xBSX.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['basex'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM'],
  },
  WELL: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/well.svg'],
    tokenAddress: addresses.BASE.WELL,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['moonwell-artemis'],
    },
  },
  WELL_old: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/well.svg'],
    tokenAddress: addresses.BASE.WELL_old,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['moonwell-artemis'],
    },
  },
  moonwell_CBETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/well.svg'],
    apyTokenSymbols: ['WELL'],
    logoUrl: ['./icons/cbeth.svg'],
    tokenNames: ['CBETH'],
    platform: ['Moonwell'],
    tags: ['Beginners', 'LSD'],
    tokenAddress: addresses.BASE.V2.moonwell_CBETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.moonwell_CBETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['coinbase-wrapped-staked-eth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MOONWELL_FOLD,
        params: [
          addresses.BASE.V2.moonwell_CBETH.Underlying,
          addresses.BASE.V2.moonwell_CBETH.mToken,
          74,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WELL'],
  },
  moonwell_DAI: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/well.svg'],
    apyTokenSymbols: ['WELL'],
    logoUrl: ['./icons/dai.svg'],
    tokenNames: ['DAI'],
    platform: ['Moonwell'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.BASE.V2.moonwell_DAI.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.moonwell_DAI.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MOONWELL_FOLD,
        params: [
          addresses.BASE.V2.moonwell_DAI.Underlying,
          addresses.BASE.V2.moonwell_DAI.mToken,
          80,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WELL'],
  },
  moonwell_WETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/well.svg'],
    apyTokenSymbols: ['WELL'],
    logoUrl: ['./icons/eth.svg'],
    tokenNames: ['ETH'],
    platform: ['Moonwell'],
    tags: ['Beginners'],
    tokenAddress: addresses.BASE.V2.moonwell_WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.moonwell_WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MOONWELL_FOLD,
        params: [
          addresses.BASE.V2.moonwell_WETH.Underlying,
          addresses.BASE.V2.moonwell_WETH.mToken,
          79,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WELL'],
  },
  'DAI+_base': {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/daiplus.svg'],
    tokenAddress: addresses.BASE.DAI_plus,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['overnight-dai'],
    },
  },
  'USD+_base': {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/usdplus.svg'],
    tokenAddress: addresses.BASE.USD_plus,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd'],
    },
  },
  aerodrome_DAIp_USDp: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/daiplus.svg', './icons/usdplus.svg'],
    tokenNames: ['DAI+', 'USD+'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.aerodrome_DAIp_USDp.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_DAIp_USDp.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_DAIp_USDp.Underlying, 'DAI+_base', 'USD+_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_DAIp_USDp.Underlying,
          addresses.BASE.V2.aerodrome_DAIp_USDp.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  DOLA_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/dola.svg'],
    tokenAddress: addresses.BASE.DOLA,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dola-usd'],
    },
  },
  aerodrome_DOLA_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/dola.svg', './icons/usdbc.svg'],
    tokenNames: ['DOLA', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.aerodrome_DOLA_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_DOLA_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_DOLA_USDC.Underlying, 'DOLA_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_DOLA_USDC.Underlying,
          addresses.BASE.V2.aerodrome_DOLA_USDC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_USDp_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/usdplus.svg', './icons/usdbc.svg'],
    tokenNames: ['USD+', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.aerodrome_USDp_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_USDp_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_USDp_USDC.Underlying, 'USD+_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_USDp_USDC.Underlying,
          addresses.BASE.V2.aerodrome_USDp_USDC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  crvUSD_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/crvusd.svg'],
    tokenAddress: addresses.BASE.crvUSD,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['crvusd'],
    },
  },
  USDC_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/usdc.svg'],
    tokenAddress: addresses.BASE.USDC_bridged,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
  },
  CRV_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/curve.svg'],
    tokenAddress: addresses.BASE.CRV,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['curve-dao-token'],
    },
  },
  curve_USDC_USDbC_axlUSDC_crvUSD: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/curve.svg', './icons/crvusd.svg'],
    apyTokenSymbols: ['CRV', 'crvUSD'],
    logoUrl: ['./icons/usdc.svg', './icons/usdbc.svg', './icons/axlusdc.svg', './icons/crvusd.svg'],
    tokenNames: ['USDC', 'USDbC', 'axlUSDC', 'crvUSD'],
    platform: ['Curve'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.curve_USDC_USDbC_axlUSDC_crvUSD.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.curve_USDC_USDbC_axlUSDC_crvUSD.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.BASE.V2.curve_USDC_USDbC_axlUSDC_crvUSD.Underlying,
        addresses.BASE.V2.curve_USDC_USDbC_axlUSDC_crvUSD.Underlying,
        '18',
        ['USDbC_base', 'USDC_base', 'axlUSDC', 'crvUSD_base'],
        CHAIN_IDS.BASE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CURVE_GAUGE,
        params: [
          'curve_USDC_USDbC_axlUSDC_crvUSD',
          addresses.BASE.V2.curve_USDC_USDbC_axlUSDC_crvUSD.Gauge,
          profitSharingCut10Percent,
          CHAIN_IDS.BASE,
          addresses.BASE.V2.curve_USDC_USDbC_axlUSDC_crvUSD.NewStrategy,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV'],
  },
  EQB: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eqb.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.EQB,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['equilibria-finance'],
    },
  },
  camelot_EQB_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eqb.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/eqb.svg', './icons/arb.svg'],
    apyTokenSymbols: ['EQB', 'ARB'],
    tokenNames: ['EQB', 'ETH'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_EQB_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_EQB_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.camelot_EQB_ETH.Underlying, 'EQB', 'WETH_arbitrum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_EQB_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_EQB_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_EQB_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'EQB'],
  },
  LODE: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/lode.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.LODE,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['lodestar'],
    },
  },
  RELAY_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/relay.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.RELAY,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['relay-token'],
    },
  },
  camelot_RELAY_ETH: {
    inactive: true,
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/relay.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/relay.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'RELAY', 'ARB'],
    tokenNames: ['RELAY', 'ETH'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_RELAY_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_RELAY_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [
        addresses.ARBITRUM_ONE.V2.camelot_RELAY_ETH.Underlying,
        'RELAY_arbitrum',
        'WETH_arbitrum',
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_RELAY_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_RELAY_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_RELAY_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'RELAY'],
  },
  THALES_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/thales.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.THALES,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['thales'],
    },
  },
  camelot_THALES_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/thales.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/thales.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'THALES', 'ARB'],
    tokenNames: ['THALES', 'ETH'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_THALES_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_THALES_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [
        addresses.ARBITRUM_ONE.V2.camelot_THALES_ETH.Underlying,
        'THALES_arbitrum',
        'WETH_arbitrum',
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_THALES_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_THALES_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_THALES_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'THALES'],
  },
  AERO: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/aero.svg'],
    tokenAddress: addresses.BASE.AERO,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.AERODROME_PAIR,
      params: [addresses.BASE.AERO, addresses.BASE.USDC],
    },
  },
  aerodrome_AERO_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/aero.svg', './icons/usdbc.svg'],
    tokenNames: ['AERO', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_AERO_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_AERO_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_AERO_USDC.Underlying, 'AERO', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_AERO_USDC.Underlying,
          addresses.BASE.V2.aerodrome_AERO_USDC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_cbETH_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/cbeth.svg', './icons/eth.svg'],
    tokenNames: ['CBETH', 'ETH'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.BASE.V2.aerodrome_cbETH_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_cbETH_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_cbETH_ETH.Underlying, 'CBETH_base', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_cbETH_ETH.Underlying,
          addresses.BASE.V2.aerodrome_cbETH_ETH.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_ETH_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/eth.svg', './icons/usdbc.svg'],
    tokenNames: ['ETH', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_ETH_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_ETH_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_ETH_USDC.Underlying, 'USDbC_base', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_ETH_USDC.Underlying,
          addresses.BASE.V2.aerodrome_ETH_USDC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  tBTC_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/tbtc.svg'],
    tokenAddress: addresses.BASE.TBTC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tbtc'],
    },
  },
  aerodrome_tBTC_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/tbtc.svg', './icons/eth.svg'],
    tokenNames: ['tBTC', 'ETH'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_tBTC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_tBTC_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_tBTC_ETH.Underlying, 'tBTC_base', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_tBTC_ETH.Underlying,
          addresses.BASE.V2.aerodrome_tBTC_ETH.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  aerodrome_tBTC_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/tbtc.svg', './icons/usdbc.svg'],
    tokenNames: ['tBTC', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.aerodrome_tBTC_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_tBTC_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_tBTC_USDC.Underlying, 'tBTC_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_tBTC_USDC.Underlying,
          addresses.BASE.V2.aerodrome_tBTC_USDC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  wUSDR_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: [''],
    tokenAddress: addresses.BASE.wUSDR,
    decimals: '9',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-usdr'],
    },
  },
  aerodrome_wUSDR_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/aero.svg'],
    apyTokenSymbols: ['AERO'],
    logoUrl: ['./icons/wusdr.svg', './icons/usdbc.svg'],
    tokenNames: ['wUSDR', 'USDbC'],
    platform: ['Aerodrome'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.aerodrome_wUSDR_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.aerodrome_wUSDR_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.aerodrome_wUSDR_USDC.Underlying, 'wUSDR_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AERODROME,
        params: [
          addresses.BASE.V2.aerodrome_wUSDR_USDC.Underlying,
          addresses.BASE.V2.aerodrome_wUSDR_USDC.Gauge,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'AERO'],
  },
  COMP: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/comp.svg'],
    tokenAddress: addresses.COMP,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['compound-governance-token'],
    },
  },
  USDbC_base: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/comp.svg'],
    apyTokenSymbols: ['COMP'],
    logoUrl: ['./icons/usdbc.svg'],
    tokenNames: ['USDbC'],
    platform: ['Compound'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.BASE.V2.USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.BASE.V2.USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.COMPOUND_V3,
        params: [
          addresses.BASE.V2.USDC.Underlying,
          addresses.BASE.V2.USDC.Market,
          profitSharingCut10Percent,
          CHAIN_IDS.BASE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'COMP'],
  },
  WETH_base: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/comp.svg'],
    apyTokenSymbols: ['COMP'],
    logoUrl: ['./icons/eth.svg'],
    tokenNames: ['ETH'],
    platform: ['Compound'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.BASE.V2.WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.COMPOUND_V3,
        params: [
          addresses.BASE.V2.WETH.Underlying,
          addresses.BASE.V2.WETH.Market,
          profitSharingCut10Percent,
          CHAIN_IDS.BASE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'COMP'],
  },
  USDCe_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    apyIconUrls: ['./icons/comp.svg'],
    apyTokenSymbols: ['COMP'],
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC.e'],
    platform: ['Compound'],
    tags: ['Beginners', 'Stable'],
    tokenAddress: addresses.MATIC.V2.USDC.Underlying,
    decimals: '6',
    vaultAddress: addresses.MATIC.V2.USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.COMPOUND_V3,
        params: [
          addresses.MATIC.V2.USDC.Underlying,
          addresses.MATIC.V2.USDC.Market,
          profitSharingCut8Percent,
          CHAIN_IDS.POLYGON_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'COMP'],
  },
  axlUSDC: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/axlusdc.svg'],
    tokenAddress: addresses.BASE.axlUSDC,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['axlusdc'],
    },
  },
  BSWAP: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/bswap.svg'],
    tokenAddress: addresses.BASE.BSWAP,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['baseswap'],
    },
  },
  BSX: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/bsx.svg'],
    tokenAddress: addresses.BASE.BSX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['basex'],
    },
  },
  CBETH_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/cbeth.svg'],
    tokenAddress: addresses.BASE.CBETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['coinbase-wrapped-staked-eth'],
    },
  },
  baseswap_BSX_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/bsx.svg', './icons/eth.svg'],
    tokenNames: ['BSX', 'ETH'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.baseswap_BSX_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_BSX_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_BSX_ETH.Underlying, 'BSX', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_BSX_ETH.NewStrategy,
          addresses.BASE.V2.baseswap_BSX_ETH.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_BSWAP_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/bswap.svg', './icons/eth.svg'],
    tokenNames: ['BSWAP', 'ETH'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.baseswap_BSWAP_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_BSWAP_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_BSWAP_ETH.Underlying, 'BSWAP', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_BSWAP_ETH.NewStrategy,
          addresses.BASE.V2.baseswap_BSWAP_ETH.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_CBETH_ETH: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/cbeth.svg', './icons/eth.svg'],
    tokenNames: ['CBETH', 'ETH'],
    platform: ['BaseSwap'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.BASE.V2.baseswap_CBETH_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_CBETH_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_CBETH_ETH.Underlying, 'CBETH_base', 'WETH_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_CBETH_ETH.NewStrategy,
          addresses.BASE.V2.baseswap_CBETH_ETH.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_DAI_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/dai.svg', './icons/usdbc.svg'],
    tokenNames: ['DAI', 'USDbC'],
    platform: ['BaseSwap'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.BASE.V2.baseswap_DAI_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_DAI_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_DAI_USDC.Underlying, 'DAI_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_DAI_USDC.NewStrategy,
          addresses.BASE.V2.baseswap_DAI_USDC.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  baseswap_ETH_USDC: {
    chain: CHAIN_IDS.BASE,
    apyIconUrls: ['./icons/bsx.svg', './icons/xbsx.svg', './icons/bswap.svg'],
    apyTokenSymbols: ['BSX', 'xBSX', 'BSWAP'],
    logoUrl: ['./icons/eth.svg', './icons/usdbc.svg'],
    tokenNames: ['ETH', 'USDbC'],
    platform: ['BaseSwap'],
    tags: ['Advanced'],
    tokenAddress: addresses.BASE.V2.baseswap_ETH_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.BASE.V2.baseswap_ETH_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.BASE.V2.baseswap_ETH_USDC.Underlying, 'WETH_base', 'USDbC_base'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BASESWAP_V2,
        params: [
          addresses.BASE.V2.baseswap_ETH_USDC.NewStrategy,
          addresses.BASE.V2.baseswap_ETH_USDC.NFTPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BSWAP'],
  },
  DAI_base: {
    chain: CHAIN_IDS.BASE,
    logoUrl: ['./icons/dai.svg'],
    tokenAddress: addresses.BASE.DAI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
  },
  iFARM_base: {
    chain: CHAIN_IDS.BASE,
    decimals: '18',
    tokenAddress: addresses.BASE.iFARM,
    tokenNames: ['iFARM'],
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.F_TOKEN, params: [addresses.iFARM, '18'] },
  },
  balancer_DOLA_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    apyIconUrls: ['./icons/balancer.svg', './icons/aura.svg', './icons/arb.svg'],
    apyTokenSymbols: ['BAL', 'AURA', 'ARB'],
    logoUrl: ['./icons/dola.svg', './icons/usdc.svg'],
    platform: ['Aura Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['DOLA', 'USDC.e'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.balancer_DOLA_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.balancer_DOLA_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.V2.balancer_DOLA_USDC.Underlying,
        addresses.ARBITRUM_ONE.V2.balancer_DOLA_USDC.PoolId,
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AURA,
        params: ['dola_usdc_arbitrum', profitSharingCut10Percent, CHAIN_IDS.ARBITRUM_ONE],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'BAL', 'AURA'],
  },
  tBTC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/tbtc.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.TBTC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tbtc'],
    },
  },
  balancer_tBTC_WBTC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/tbtc.svg', './icons/wbtc.svg'],
    apyIconUrls: ['./icons/balancer.svg', './icons/aura.svg', './icons/arb.svg'],
    apyTokenSymbols: ['BAL', 'AURA', 'ARB'],
    platform: ['Aura Finance'],
    tokenNames: ['tBTC', 'WBTC'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.balancer_tBTC_WBTC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.balancer_tBTC_WBTC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.V2.balancer_tBTC_WBTC.Underlying,
        addresses.ARBITRUM_ONE.V2.balancer_tBTC_WBTC.PoolId,
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AURA,
        params: ['tbtc_wbtc_arbitrum', profitSharingCut10Percent, CHAIN_IDS.ARBITRUM_ONE],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BAL', 'AURA'],
  },
  balancer_tBTC_WETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/tbtc.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/balancer.svg', './icons/aura.svg', './icons/arb.svg'],
    apyTokenSymbols: ['BAL', 'AURA', 'ARB'],
    platform: ['Aura Finance'],
    tokenNames: ['tBTC', 'ETH'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.balancer_tBTC_WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.balancer_tBTC_WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.V2.balancer_tBTC_WETH.Underlying,
        addresses.ARBITRUM_ONE.V2.balancer_tBTC_WETH.PoolId,
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AURA,
        params: ['tbtc_weth_arbitrum', profitSharingCut10Percent, CHAIN_IDS.ARBITRUM_ONE],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BAL', 'AURA'],
  },
  GENE_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/gene.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.GENE,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['genomesdao'],
    },
  },
  GNOME_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/gnome.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.GNOME,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['gnome'],
    },
  },
  camelot_GENE_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/gene.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/gnome.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GNOME', 'ARB'],
    tokenNames: ['GENE', 'ETH'],
    platform: ['Camelot'],
    tags: ['Advanced', 'DeSci'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_GENE_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_GENE_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [
        addresses.ARBITRUM_ONE.V2.camelot_GENE_ETH.Underlying,
        'GENE_arbitrum',
        'WETH_arbitrum',
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_GENE_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_GENE_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_GENE_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GNOME'],
  },
  camelot_GNOME_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/gnome.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/gene.svg'],
    apyTokenSymbols: ['GENE'],
    tokenNames: ['GNOME', 'ETH'],
    platform: ['Camelot'],
    tags: ['Advanced', 'DeSci'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_GNOME_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_GNOME_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [
        addresses.ARBITRUM_ONE.V2.camelot_GNOME_ETH.Underlying,
        'GNOME_arbitrum',
        'WETH_arbitrum',
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_GNOME_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_GNOME_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_GNOME_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GENE'],
  },
  camelot_GRAIL_ARB: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/grail.svg', './icons/arb.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL'],
    tokenNames: ['GRAIL', 'ARB'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_GRAIL_ARB.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_GRAIL_ARB.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.camelot_GRAIL_ARB.Underlying, 'GRAIL', 'ARB'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_GRAIL_ARB.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_GRAIL_ARB.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_GRAIL_ARB.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL'],
  },
  GMX: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/gmx.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.GMX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['gmx'],
    },
  },
  camelot_iFARM_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/ifarm.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg', './icons/arb.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL', 'ARB'],
    tokenNames: ['iFARM', 'ETH'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_iFARM_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_iFARM_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [
        addresses.ARBITRUM_ONE.V2.camelot_iFARM_ETH.Underlying,
        'iFARM_arbitrum',
        'WETH_arbitrum',
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_iFARM_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_iFARM_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_iFARM_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL', 'ARB'],
  },
  camelot_ARB_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/arb.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL'],
    tokenNames: ['ARB', 'ETH'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_ARB_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_ARB_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.camelot_ARB_ETH.Underlying, 'ARB', 'WETH_arbitrum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_ARB_ETH.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_ARB_ETH.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_ARB_ETH.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL'],
  },
  camelot_ETH_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eth.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/grail.svg', './icons/xgrail.svg'],
    apyTokenSymbols: ['GRAIL', 'xGRAIL'],
    tokenNames: ['ETH', 'USDC.e'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.camelot_ETH_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.camelot_ETH_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [
        addresses.ARBITRUM_ONE.V2.camelot_ETH_USDC.Underlying,
        'WETH_arbitrum',
        'USDC_arbitrum',
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_LP,
        params: [
          addresses.ARBITRUM_ONE.V2.camelot_ETH_USDC.NewStrategy,
          addresses.ARBITRUM_ONE.V2.camelot_ETH_USDC.NFTPool,
          addresses.ARBITRUM_ONE.V2.camelot_ETH_USDC.NitroPool,
          profitSharingCut10Percent,
        ],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'GRAIL', 'xGRAIL'],
  },
  fxGRAIL: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.V2.xGRAIL.NewVault,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.F_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.xGRAIL.NewVault, '18', CHAIN_IDS.ARBITRUM_ONE],
    },
  },
  xGRAIL: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/xgrail.svg'],
    apyIconUrls: ['./icons/eth.svg', './icons/usdc.svg', './icons/xgrail.svg', './icons/dmt.svg'],
    apyTokenSymbols: ['ETH-USDC.e', 'xGRAIL', 'DMT'],
    tokenNames: ['xGRAIL'],
    platform: ['Camelot'],
    tags: ['Advanced'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.xGRAIL.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.xGRAIL.NewVault,
    strategyAddress: addresses.ARBITRUM_ONE.V2.xGRAIL.NewStrategy,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['camelot-token'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CAMELOT_XGRAIL,
        params: [addresses.ARBITRUM_ONE.V2.xGRAIL.NewStrategy, profitSharingCut10Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'ETH-USDC.e', 'xGRAIL'],
  },
  GRAIL: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/grail.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.GRAIL,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['camelot-token'],
    },
  },
  DMT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/dmt.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.DMT,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dream-machine-token'],
    },
  },
  aWETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/am-weth.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.aWETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['ethereum'],
    },
  },
  waWETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.waWETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.WRAPPED_TOKEN,
      params: [addresses.ARBITRUM_ONE.waWETH, addresses.ARBITRUM_ONE.aWETH, CHAIN_IDS.ARBITRUM_ONE],
    },
  },
  'bb-aWETH_arbitrum': {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.bbaWETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.bbaWETH,
        '0xda1cd1711743e57dd57102e9e61b75f3587703da0000000000000000000003fc',
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
  },
  aDAI_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/am-dai.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.aDAI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
  },
  waDAI_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.waDAI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.WRAPPED_TOKEN,
      params: [addresses.ARBITRUM_ONE.waDAI, addresses.ARBITRUM_ONE.aDAI, CHAIN_IDS.ARBITRUM_ONE],
    },
  },
  aUSDC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/am-usdc.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.aUSDC,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usd-coin'],
    },
  },
  waUSDC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.waUSDC,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.WRAPPED_TOKEN,
      params: [addresses.ARBITRUM_ONE.waUSDC, addresses.ARBITRUM_ONE.aUSDC, CHAIN_IDS.ARBITRUM_ONE],
    },
  },
  aUSDT_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/am-usdt.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.aUSDT,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['tether'],
    },
  },
  waUSDT_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.waUSDT,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.WRAPPED_TOKEN,
      params: [addresses.ARBITRUM_ONE.waUSDT, addresses.ARBITRUM_ONE.aUSDT, CHAIN_IDS.ARBITRUM_ONE],
    },
  },
  'bb-aDAI_arbitrum': {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.bbaDAI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.bbaDAI,
        '0x9e34631547adcf2f8cefa0f5f223955c7b137571000000000000000000000407',
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
  },
  'bb-aUSDC_arbitrum': {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.bbaUSDC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.bbaUSDC,
        '0x7c82a23b4c48d796dee36a9ca215b641c6a8709d000000000000000000000406',
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
  },
  'bb-aUSDT_arbitrum': {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.bbaUSDT,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.bbaUSDT,
        '0x4739e50b59b552d490d3fdc60d200977a38510c0000000000000000000000409',
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
  },
  rETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [],
    tokenAddress: addresses.ARBITRUM_ONE.rETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['rocket-pool-eth'],
    },
  },
  amETH: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/am-weth.svg'],
    tokenAddress: addresses.MATIC.amETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['ethereum'],
    },
  },
  wamETH: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.wamETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.WRAPPED_TOKEN,
      params: [addresses.MATIC.wamETH, addresses.MATIC.amETH, CHAIN_IDS.POLYGON_MAINNET],
    },
  },
  'bb-amETH': {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.bbamETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.MATIC.bbamETH,
        '0x43894de14462b421372bcfe445fa51b1b4a0ff3d000000000000000000000b36',
        CHAIN_IDS.POLYGON_MAINNET,
      ],
    },
  },
  OETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/oeth.svg'],
    tokenAddress: addresses.OETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['origin-ether'],
    },
  },
  curve_OETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/curve.svg', './icons/cvx.svg'],
    apyTokenSymbols: ['CRV', 'CVX'],
    logoUrl: ['./icons/oeth.svg', './icons/eth.svg'],
    tokenNames: ['OETH', 'ETH'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.V2.curve_OETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.curve_OETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.V2.curve_OETH.Underlying,
        addresses.V2.curve_OETH.Underlying,
        '18',
        ['OETH', 'ETH'],
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['oeth', profitSharingCut15Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CVX', 'CRV'],
  },
  crvUSD: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/crvusd.svg'],
    tokenAddress: addresses.crvUSD,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['crvusd'] },
  },
  curve_crvUSD_USDC: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/crvusd.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/curve.svg', './icons/cvx.svg'],
    apyTokenSymbols: ['CRV', 'CVX'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'Stable'],
    tokenNames: ['crvUSD', 'USDC'],
    tokenAddress: addresses.V2.curve_crvUSD_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.curve_crvUSD_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.V2.curve_crvUSD_USDC.Underlying,
        addresses.V2.curve_crvUSD_USDC.Underlying,
        18,
        ['crvUSD', 'USDC'],
        CHAIN_IDS.ETH_MAINNET,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['crvusdc', profitSharingCut15Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CRV'],
  },
  AURA_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.AURA,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['aura-finance'],
    },
  },
  balancer_2EUR_PAR_v2: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/jeur.svg', './icons/par.svg'],
    apyIconUrls: ['./icons/balancer.svg', './icons/aura.svg'],
    apyTokenSymbols: ['BAL', 'AURA'],
    tokenNames: ['jEUR', 'PAR'],
    platform: ['Aura Finance'],
    tags: ['Advanced', 'Stable'],
    tokenAddress: addresses.MATIC.V2.balancer_2EUR_PAR_v2.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.balancer_2EUR_PAR_v2.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.MATIC.V2.balancer_2EUR_PAR_v2.Underlying,
        addresses.MATIC.V2.balancer_2EUR_PAR_v2.PoolId,
        CHAIN_IDS.POLYGON_MAINNET,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AURA,
        params: ['jeur_par_polygon', profitSharingCut8Percent, CHAIN_IDS.POLYGON_MAINNET],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BAL'],
  },
  USDR_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/usdr.svg'],
    tokenAddress: addresses.MATIC.USDR,
    decimals: '9',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['real-usd'] },
  },
  USDR_old_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/usdr.svg'],
    tokenAddress: addresses.MATIC.USDR_old,
    decimals: '9',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['real-usd'] },
  },
  RDNT: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/rdnt.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.RDNT,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['radiant-capital'] },
  },
  balancer_RDNT_ETH: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/rdnt.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/balancer.svg', './icons/aura.svg', './icons/arb.svg'],
    apyTokenSymbols: ['BAL', 'AURA', 'ARB'],
    platform: ['Aura Finance'],
    tags: ['Advanced'],
    tokenNames: ['RDNT', 'ETH'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.balancer_RDNT_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.balancer_RDNT_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.ARBITRUM_ONE.V2.balancer_RDNT_ETH.Underlying,
        addresses.ARBITRUM_ONE.V2.balancer_RDNT_ETH.PoolId,
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.AURA,
        params: ['rdnt_eth_arbitrum', profitSharingCut10Percent, CHAIN_IDS.ARBITRUM_ONE],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BAL', 'AURA'],
  },
  USDPlus_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/usdplus.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.USDPlus,
    decimals: '6',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['usd'] },
  },
  FRAX_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/frax.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.FRAX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['frax'] },
  },
  ARB: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/arb.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.ARB,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['arbitrum'] },
  },
  DPX_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/dpx.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.DPX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['dopex'] },
  },
  RDPX_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/rdpx.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.RDPX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_ID, params: ['dopex-rebate-token'] },
  },
  sushi_DPX_ETH: {
    inactive: true,
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/dpx.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/sushi.svg', './icons/dpx.svg'],
    apyTokenSymbols: ['SUSHI', 'DPX'],
    platform: ['SushiSwap'],
    tags: ['Advanced'],
    tokenNames: ['DPX', 'ETH'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.sushi_DPX_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.sushi_DPX_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.sushi_DPX_ETH.Underlying, 'WETH_arbitrum', 'DPX_arbitrum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI,
        params: [
          addresses.ARBITRUM_ONE.V2.sushi_DPX_ETH.PoolId,
          'WETH_arbitrum',
          'DPX_arbitrum',
          profitSharingCut10Percent,
          CHAIN_IDS.ARBITRUM_ONE,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'DPX'],
  },
  pCRV: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.CRV,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['curve-dao-token'],
    },
  },
  frxETH_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.frxETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['frax-ether'],
    },
  },
  SPHERE: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.SPHERE,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['sphere-finance'],
    },
  },
  wstETH_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.wstETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
  },
  wUSDR_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.wUSDR,
    decimals: '9',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-usdr'],
    },
  },
  STAR_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [''],
    tokenAddress: addresses.MATIC.STAR,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['preon-star'],
    },
  },
  POISON: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/poison.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.POISON,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['poison-finance'],
    },
  },
  iPoison: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/ipoison.svg'],
    apyIconUrls: ['./icons/poison.svg'],
    apyTokenSymbols: ['POISON'],
    subLabel: 'Auto-compounding',
    platform: ['Poison'],
    tags: ['Advanced'],
    tokenNames: ['iPoison'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.iPoison.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.iPoison.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.WRAPPED_TOKEN,
      params: [
        addresses.ARBITRUM_ONE.V2.iPoison.Underlying,
        addresses.ARBITRUM_ONE.POISON,
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.POISON,
        params: [
          addresses.ARBITRUM_ONE.V2.iPoison.Underlying,
          addresses.ARBITRUM_ONE.V2.iPoison.PoolId,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'POISON'],
  },
  poison_pGOLD_USDC: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/pgold.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/poison.svg'],
    apyTokenSymbols: ['POISON'],
    subLabel: 'Auto-compounding',
    platform: ['Poison'],
    tags: ['Advanced'],
    tokenNames: ['pGOLD', 'USDC.e'],
    tokenAddress: addresses.ARBITRUM_ONE.V2.poison_pGOLD_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.ARBITRUM_ONE.V2.poison_pGOLD_USDC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.poison_pGOLD_USDC.Underlying, 'pGOLD', 'USDC_arbitrum'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.POISON,
        params: [
          addresses.ARBITRUM_ONE.V2.poison_pGOLD_USDC.Underlying,
          addresses.ARBITRUM_ONE.V2.poison_pGOLD_USDC.PoolId,
          profitSharingCut10Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'POISON'],
  },
  fiPoison: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [''],
    tokenAddress: addresses.ARBITRUM_ONE.V2.iPoison.NewVault,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.F_TOKEN,
      params: [addresses.ARBITRUM_ONE.V2.iPoison.NewVault, '18', CHAIN_IDS.ARBITRUM_ONE],
    },
  },
  pGOLD: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/pgold.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.pGOLD,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.SUSHISWAP_PAIR,
      params: [
        addresses.ARBITRUM_ONE.pGOLD,
        addresses.ARBITRUM_ONE.USDC,
        6,
        CHAIN_IDS.ARBITRUM_ONE,
      ],
    },
  },
  iFARM_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    decimals: '18',
    tokenAddress: addresses.ARBITRUM_ONE.iFARM,
    tokenNames: ['iFARM'],
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.F_TOKEN, params: [addresses.iFARM, '18'] },
  },
  USDT_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/usdt.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.USDT,
    decimals: '6',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.USDT] },
  },
  WBTC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/wbtc.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.WBTC,
    decimals: '8',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WBTC] },
  },
  RENBTC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/ren.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.RENBTC,
    decimals: '8',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.RENBTC] },
  },
  WETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eth.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.WETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WETH] },
  },
  EURS_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/eurs.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.EURS,
    decimals: '2',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.EURS] },
  },
  DAI_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    tokenAddress: addresses.ARBITRUM_ONE.DAI,
    logoUrl: ['./icons/dai.svg'],
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.DAI] },
  },
  MAGIC_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    tokenAddress: addresses.ARBITRUM_ONE.MAGIC,
    logoUrl: ['./icons/magic.svg'],
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.MAGIC] },
  },
  MIM_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    tokenAddress: addresses.ARBITRUM_ONE.MIM,
    logoUrl: ['./icons/mim.svg'],
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.MIM] },
  },
  SPELL_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    tokenAddress: addresses.ARBITRUM_ONE.SPELL,
    logoUrl: ['./icons/spell.svg'],
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.SPELL] },
  },
  SUSHI_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    tokenAddress: addresses.ARBITRUM_ONE.SUSHI,
    logoUrl: ['./icons/sushi.svg'],
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.SUSHI] },
  },
  quickGamma_MATIC_ETH_narrow: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/matic.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/quick.svg', './icons/matic.svg'],
    apyTokenSymbols: ['dQUICK', 'WMATIC'],
    subLabel: 'Narrow',
    tokenNames: ['MATIC', 'ETH'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.quickGamma_MATIC_ETH_narrow.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickGamma_MATIC_ETH_narrow.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [
        addresses.MATIC.V2.quickGamma_MATIC_ETH_narrow.Underlying,
        CHAIN_IDS.POLYGON_MAINNET,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.quickGamma_MATIC_ETH_narrow.Underlying,
          addresses.MATIC.V2.quickGamma_MATIC_ETH_narrow.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK', 'WMATIC'],
  },
  quickGamma_MATIC_ETH_wide: {
    inactive: true,
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/matic.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/quick.svg', './icons/matic.svg'],
    apyTokenSymbols: ['dQUICK', 'WMATIC'],
    subLabel: 'Wide',
    tokenNames: ['MATIC', 'ETH'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.quickGamma_MATIC_ETH_wide.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickGamma_MATIC_ETH_wide.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.quickGamma_MATIC_ETH_wide.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.GAMMA_API,
        params: [
          addresses.MATIC.V2.quickGamma_MATIC_ETH_wide.MasterChef,
          addresses.MATIC.V2.quickGamma_MATIC_ETH_wide.Underlying,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK', 'WMATIC'],
  },
  quickGamma_MATIC_USDC_narrow: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/matic.svg', './icons/usdc.svg'],
    apyIconUrls: ['./icons/quick.svg', './icons/matic.svg'],
    apyTokenSymbols: ['dQUICK', 'WMATIC'],
    subLabel: 'Narrow',
    tokenNames: ['MATIC', 'USDC.e'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.quickGamma_MATIC_USDC_narrow.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickGamma_MATIC_USDC_narrow.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [
        addresses.MATIC.V2.quickGamma_MATIC_USDC_narrow.Underlying,
        CHAIN_IDS.POLYGON_MAINNET,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.quickGamma_MATIC_USDC_narrow.Underlying,
          addresses.MATIC.V2.quickGamma_MATIC_USDC_narrow.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK', 'WMATIC'],
  },
  quickGamma_USDC_ETH_narrow: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/usdc.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/quick.svg', './icons/matic.svg'],
    apyTokenSymbols: ['dQUICK', 'WMATIC'],
    subLabel: 'Narrow',
    tokenNames: ['USDC.e', 'ETH'],
    platform: ['Quickswap', 'Gamma'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.quickGamma_USDC_ETH_narrow.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickGamma_USDC_ETH_narrow.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.GAMMA_ONCHAIN,
      params: [addresses.MATIC.V2.quickGamma_USDC_ETH_narrow.Underlying, CHAIN_IDS.POLYGON_MAINNET],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MERKL,
        params: [
          addresses.MATIC.V2.quickGamma_USDC_ETH_narrow.Underlying,
          addresses.MATIC.V2.quickGamma_USDC_ETH_narrow.PoolAddress,
          CHAIN_IDS.POLYGON_MAINNET,
          profitSharingCut8Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'dQUICK', 'WMATIC'],
  },
  SWISE: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.SWISE,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['stakewise'],
    },
  },
  LDO: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.LDO,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['lido-dao'],
    },
  },
  wstETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.wstETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
  },
  wstETH_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [],
    tokenAddress: addresses.ARBITRUM_ONE.wstETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['wrapped-steth'],
    },
  },
  stMATIC: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.stMATIC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['lido-staked-matic'],
    },
  },
  maticX: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.maticX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['stader-maticx'],
    },
  },
  pSD: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.SD,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['stader'],
    },
  },
  pLDO: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.LDO,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['lido-dao'],
    },
  },
  bal_POLYBASE: {
    inactive: true,
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/matic.svg', './icons/usdc.svg', './icons/eth.svg', './icons/balancer.svg'],
    apyIconUrls: ['./icons/balancer.svg'],
    apyTokenSymbols: ['BAL'],
    tokenNames: ['MATIC', 'USDC', 'ETH', 'BAL'],
    platform: ['Balancer'],
    tags: ['Advanced'],
    tokenAddress: addresses.MATIC.V2.balancer_POLYBASE.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.balancer_POLYBASE.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.MATIC.V2.balancer_POLYBASE.Underlying,
        addresses.MATIC.V2.balancer_POLYBASE.PoolId,
        CHAIN_IDS.POLYGON_MAINNET,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BALANCER_GAUGE_SIDECHAIN,
        params: [
          'bal_POLYBASE',
          addresses.MATIC.V2.balancer_POLYBASE.Gauge,
          profitSharingCut8Percent,
          CHAIN_IDS.POLYGON_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BAL'],
  },
  '80BAL-20WETH': {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.balWeth,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.MATIC.balWeth,
        '0x3d468ab2329f296e1b9d8476bb54dd77d8c2320f000200000000000000000426',
        CHAIN_IDS.POLYGON_MAINNET,
      ],
    },
  },
  USDD: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/usdd.png'],
    tokenAddress: addresses.USDD,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['usdd'],
    },
  },
  DOLA: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/dola.svg'],
    tokenAddress: addresses.DOLA,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dola-usd'],
    },
  },
  FRAX: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/frax.svg'],
    tokenAddress: addresses.FRAX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['frax'],
    },
  },
  FRAXBP: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/fraxbp.png'],
    tokenAddress: addresses.FRAXBP,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [addresses.FRAXBP_Miner, addresses.FRAXBP, '18', ['FRAX', 'USDC']],
    },
  },
  pETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/peth.svg'],
    tokenAddress: addresses.pETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['peth'],
    },
  },
  convex_pETH: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/curve.svg', './icons/cvx.svg'],
    apyTokenSymbols: ['CRV', 'CVX'],
    logoUrl: ['./icons/peth.svg', './icons/eth.svg'],
    tokenNames: ['pETH', 'ETH'],
    platform: ['Convex Finance'],
    tags: ['Advanced'],
    tokenAddress: addresses.V2.convex_pETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.convex_pETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['weth'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['peth', profitSharingCut15Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CVX', 'CRV'],
  },
  pBTC: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/pbtc.png'],
    tokenAddress: addresses.pBTC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['ptokens-btc-2'],
    },
  },
  sBTC: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/sbtc.png'],
    tokenAddress: addresses.sBTC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['sbtc'],
    },
  },
  sbtcCrv: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/sbtccrv.png'],
    tokenAddress: addresses.sbtcCrv,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [addresses.sbtcCrv_Miner, addresses.sbtcCrv, '18', ['WBTC', 'sBTC', 'RENBTC']],
    },
  },
  CVX: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/cvx.svg'],
    tokenAddress: addresses.CVX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['convex-finance'],
    },
  },
  convex_CVX_ETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/curve.svg', './icons/cvx.svg'],
    apyTokenSymbols: ['CRV', 'CVX'],
    logoUrl: ['./icons/eth.svg', './icons/cvx.svg'],
    tokenNames: ['CVX', 'ETH'],
    platform: ['Convex Finance'],
    tags: ['Advanced'],
    tokenAddress: addresses.V2.convex_CVX_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.convex_CVX_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.V2.convex_CVX_ETH.Miner,
        addresses.V2.convex_CVX_ETH.Underlying,
        '18',
        ['CVX', 'ETH'],
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['cvxeth', profitSharingCut15Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CVX', 'CRV'],
  },
  stETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/steth.svg'],
    tokenAddress: addresses.stETH,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['staked-ether'],
    },
  },
  crvSTETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/curve.svg', './icons/cvx.svg'],
    apyTokenSymbols: ['CRV', 'CVX'],
    logoUrl: ['./icons/steth.svg', './icons/eth.svg'],
    tokenNames: ['stETH', 'ETH'],
    platform: ['Convex Finance'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.V2.crvSTETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.crvSTETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.V2.crvSTETH.Miner,
        addresses.V2.crvSTETH.Underlying,
        '18',
        ['stETH', 'ETH'],
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['steth-ng', profitSharingCut15Percent],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CVX', 'CRV'],
  },
  crvThreeCrypto: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/usdt.svg', './icons/wbtc.svg', './icons/eth.svg'],
    tokenNames: ['USDT', 'WBTC', 'ETH'],
    platform: ['Curve'],
    tokenAddress: addresses.V2.crvThreeCrypto.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.crvThreeCrypto.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [
        addresses.V2.crvThreeCrypto.Miner,
        addresses.V2.crvThreeCrypto.Underlying,
        '18',
        ['WBTC', 'WETH', 'USDT'],
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['tricrypto2', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CVX', 'CRV'],
  },
  notional_WBTC: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/wbtc.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['nWBTC'],
    platform: ['Notional'],
    tokenAddress: addresses.V2.notional_WBTC.Underlying,
    decimals: '8',
    vaultAddress: addresses.V2.notional_WBTC.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.NOTIONAL,
      params: [4, addresses.V2.notional_WBTC.Underlying, addresses.WBTC],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.NOTIONAL,
        params: [4, addresses.NOTE, addresses.V2.notional_WBTC.Underlying, addresses.WBTC, '0.7'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'NOTE'],
  },
  convex_cvxCRV: {
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/curve.svg', './icons/cvx.svg', './icons/3crv.svg'],
    apyTokenSymbols: ['CRV', 'CVX', '3CRV'],
    logoUrl: ['./icons/curve.svg', './icons/cvx.svg'],
    tokenNames: ['cvxCRV'],
    platform: ['Convex Finance'],
    tags: ['Advanced'],
    tokenAddress: addresses.V2.convex_cvxCRV.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.convex_cvxCRV.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.cvxCRV] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['convex_cvxCRV', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CVX', 'CRV'],
  },
  USDC: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/usdc.svg'],
    tokenNames: ['USDC'],
    platform: ['Idle'],
    tags: ['Beginners', 'Stable'],
    apyIconUrls: ['./icons/comp.svg', './icons/idle.svg'],
    apyTokenSymbols: ['COMP', 'IDLE'],
    tokenAddress: addresses.USDC,
    decimals: '6',
    vaultAddress: addresses.V2.USDC.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.USDC] },
    cmcRewardTokenSymbols: ['FARM', 'USDC'],
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.IDLE_FINANCE,
        params: ['USDC', '0x5274891bEC421B39D23760c04A6755eCB444797C', '0.7'],
      },
    ],
  },
  DAI: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/dai.svg'],
    tokenNames: ['DAI'],
    platform: ['Idle'],
    tags: ['Beginners', 'Stable'],
    apyIconUrls: ['./icons/comp.svg', './icons/idle.svg'],
    apyTokenSymbols: ['COMP', 'IDLE'],
    tokenAddress: addresses.DAI,
    decimals: '18',
    vaultAddress: addresses.V2.DAI.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.DAI] },
    cmcRewardTokenSymbols: ['FARM', 'DAI'],
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.IDLE_FINANCE,
        params: ['DAI', '0x3fe7940616e5bc47b0775a0dccf6237893353bb4', '0.7'],
      },
    ],
  },
  USDT: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/usdt.svg'],
    tokenNames: ['USDT'],
    platform: ['Idle'],
    tags: ['Beginners', 'Stable'],
    apyIconUrls: ['./icons/comp.svg', './icons/idle.svg'],
    apyTokenSymbols: ['COMP', 'IDLE'],
    tokenAddress: addresses.USDT,
    decimals: '6',
    vaultAddress: addresses.V2.USDT.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.USDT] },
    cmcRewardTokenSymbols: ['FARM', 'USDT'],
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.IDLE_FINANCE,
        params: ['USDT', '0xF34842d05A1c888Ca02769A633DF37177415C2f8', '0.7'],
      },
    ],
  },
  LOOKS: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/looks.svg'],
    tokenAddress: addresses.LOOKS,
    decimals: '18',
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['looksrare'],
    },
    apyIconUrls: ['./icons/eth.svg'],
    apyTokenSymbols: ['WETH'],
    tokenNames: ['LOOKS'],
    platform: ['LooksRare'],
    tags: ['Advanced'],
    vaultAddress: addresses.V2.looks_LOOKS.NewVault,
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.LOOKSRARE_SINGLE_ASSET,
        params: [addresses.V2.looks_LOOKS.RewardPool, profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LOOKS'],
  },
  uni_LOOKS_ETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    apyIconUrls: ['./icons/looks.svg'],
    apyTokenSymbols: ['LOOKS'],
    logoUrl: ['./icons/looks.svg', './icons/eth.svg'],
    tokenNames: ['LOOKS', 'ETH'],
    platform: ['LooksRare'],
    tokenAddress: addresses.V2.uni_LOOKS_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.uni_LOOKS_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.uni_LOOKS_ETH.Underlying, 'LOOKS', 'WETH'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'LOOKS'],
  },
  pGENE: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/gene.svg'],
    tokenAddress: addresses.MATIC.pGENE,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['genomesdao'],
    },
  },
  pGNOME: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/gnome.svg'],
    tokenAddress: addresses.MATIC.pGNOME,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['gnome'],
    },
  },
  SUSHI_GENE_ETH: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/gene.svg', './icons/eth.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['GENE', 'ETH'],
    platform: ['Sushiswap', 'Genomes'],
    tags: ['Advanced', 'DeSci'],
    tokenAddress: addresses.MATIC.V2.SUSHI_GENE_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.SUSHI_GENE_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.MATIC.V2.SUSHI_GENE_ETH.Underlying, 'pGENE', 'WETH_polygon'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['miFARM', 'pGNOME'],
  },
  SUSHI_GNOME_ETH: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/gnome.svg', './icons/eth.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['GNOME', 'ETH'],
    platform: ['Sushiswap', 'Genomes'],
    tags: ['Advanced', 'DeSci'],
    tokenAddress: addresses.MATIC.V2.SUSHI_GNOME_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.SUSHI_GNOME_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.MATIC.V2.SUSHI_GNOME_ETH.Underlying, 'pGNOME', 'WETH_polygon'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['miFARM', 'pGENE'],
  },
  IDLE: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/idle.svg'],
    tokenAddress: addresses.IDLE,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['idle'],
    },
  },
  ORC: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/orc.svg'],
    tokenAddress: addresses.ORC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['orbit-chain'],
    },
  },
  UniV3_ORC_ETH: {
    disableAutoSwap: true,
    isSingleAssetWithdrawalAllowed: false,
    chain: CHAIN_IDS.ETH_MAINNET,
    subLabel: 'Orbit Chain',
    isUniv3: true,
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/orc.svg', './icons/eth.svg'],
    tokenNames: ['ORC', 'ETH'],
    platform: ['Uniswap V3'],
    tags: ['Advanced'],
    tokenAddress: addresses.V2.UniV3_ORC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_ORC_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_ORC_ETH.NewVault, 'ORC', 'WETH'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'ORC', 'WETH'],
  },
  CNG: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/cng.svg'],
    tokenAddress: addresses.CNG,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.UNISWAP_V3,
      params: ['CNG', 'WETH', 10000],
    },
  },
  ecoCNG: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/ecocng.svg'],
    tokenAddress: addresses.ecoCNG,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.UNISWAP_V3,
      params: ['CNG', 'WETH', 10000],
    },
  },
  UniV3_CNG_ETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    subLabel: 'Changer',
    isUniv3: true,
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/eth.svg', './icons/cng.svg'],
    tokenNames: ['CNG', 'ETH'],
    platform: ['Uniswap V3'],
    tags: ['Advanced'],
    tokenAddress: addresses.V2.UniV3_CNG_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_CNG_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_CNG_ETH.NewVault, 'CNG', 'WETH'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'CNG', 'WETH'],
    disableAutoSwap: true,
  },
  YEL: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/yel.svg'],
    tokenAddress: addresses.YEL,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['yel-finance'],
    },
  },
  sushi_YEL_ETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/yel.svg', './icons/eth.svg'],
    apyIconUrls: ['./icons/yel.svg'],
    apyTokenSymbols: ['YEL'],
    tokenNames: ['YEL', 'ETH'],
    platform: ['Sushiswap'],
    tags: ['Advanced'],
    tokenAddress: addresses.V2.sushi_YEL_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_YEL_ETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.sushi_YEL_ETH.Underlying, 'YEL', 'WETH'],
    },
    estimateApyFunctions: [
      {
        type: 'YEL',
        params: [addresses.V2.sushi_YEL_ETH.PoolId, profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'YEL', 'ETH'],
  },
  UniV3_ETH_sETH2: {
    zapFrontrunProtection: true,
    subLabel: 'StakeWise',
    chain: CHAIN_IDS.ETH_MAINNET,
    isUniv3: true,
    apyIconUrls: ['./icons/univ3.svg', './icons/reth2.svg', './icons/swise.svg'],
    apyTokenSymbols: ['UNI', 'rETH2', 'SWISE'],
    logoUrl: ['./icons/eth.svg', './icons/seth.svg'],
    tokenNames: ['ETH', 'sETH2'],
    platform: ['Uniswap V3'],
    tags: ['Advanced', 'LSD'],
    tokenAddress: addresses.V2.UniV3_ETH_sETH2.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_ETH_sETH2.NewVault,
    pricesInfo: {
      ETH: ['Min price: 1.006 sETH2 per ETH', 'Max price: 1.0121 sETH2 per ETH'],
      sETH2: ['Min price: 0.98807 ETH per sETH2', 'Max price: 0.99402 ETH per sETH2'],
    },
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_ETH_sETH2.NewVault, 'WETH', 'sETH2'],
    },
    estimateApyFunctions: [
      {
        type: 'STAKEWISE',
        params: [
          addresses.V2.UniV3_ETH_sETH2.NewVault,
          '0x7379e81228514a1D2a6Cf7559203998E20598346',
          profitSharingCut15Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WETH', 'sETH2', 'rETH2', 'SWISE'],
  },
  quick_IFARM_QUICK: {
    inactive: true,
    chain: CHAIN_IDS.POLYGON_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/ifarm.svg', './icons/quick.svg'],
    tokenNames: ['IFARM', 'QUICK'],
    platform: ['Quickswap'],
    tokenAddress: addresses.MATIC.V2.quickswap_IFARM_QUICK.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.quickswap_IFARM_QUICK.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.MATIC.V2.quickswap_IFARM_QUICK.Underlying, 'miFARM', 'QUICK'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['miFARM'],
  },
  WETH_polygon: {
    inactive: true,
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/eth.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH'],
    platform: ['IDLE'],
    tokenAddress: addresses.MATIC.V2.polygon_WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.MATIC.V2.polygon_WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_CONTRACT,
      params: [addresses.WETH],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.IDLE_FINANCE,
        params: [
          'WETH_polygon',
          '0xfdA25D931258Df948ffecb66b5518299Df6527C4',
          profitSharingCut8Percent,
          CHAIN_IDS.POLYGON_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'wMATIC'],
  },
  USDT_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/usdt.svg'],
    tokenAddress: addresses.MATIC.USDT,
    decimals: '6',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_CONTRACT,
      params: [addresses.USDT],
    },
  },
  WBTC_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/wbtc.svg'],
    tokenAddress: addresses.MATIC.WBTC,
    decimals: '8',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_CONTRACT,
      params: [addresses.WBTC],
    },
  },
  DAI_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.DAI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dai'],
    },
  },
  miFARM: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    decimals: '18',
    tokenAddress: addresses.MATIC.miFARM,
    tokenNames: ['miFARM'],
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.F_TOKEN, params: [addresses.iFARM, '18'] },
  },
  WMATIC: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    decimals: '18',
    logoUrl: ['./icons/matic.svg'],
    tokenAddress: addresses.MATIC.WMATIC,
    tokenNames: ['MATIC'],
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['matic-network'],
    },
  },
  QUICK: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/quick.svg'],
    tokenAddress: addresses.MATIC.QUICK,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['quick'],
    },
  },
  dQUICK: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/quick.svg'],
    tokenAddress: addresses.MATIC.dQUICK,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['dragons-quick'],
    },
  },
  JRT: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/jrt.svg'],
    tokenAddress: addresses.MATIC.JRT,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['jarvis-reward-token'],
    },
  },
  AURA: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.AURA,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['aura-finance'],
    },
  },
  BAL: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/balancer.svg'],
    tokenAddress: addresses.BAL,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['balancer'],
    },
  },
  BAL_polygon: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/balancer.svg'],
    tokenAddress: addresses.MATIC.BAL,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['balancer'],
    },
  },
  BAL_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: ['./icons/balancer.svg'],
    tokenAddress: addresses.ARBITRUM_ONE.BAL,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['balancer'],
    },
  },
  LDO_arbitrum: {
    chain: CHAIN_IDS.ARBITRUM_ONE,
    logoUrl: [],
    tokenAddress: addresses.ARBITRUM_ONE.LDO,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['lido-dao'],
    },
  },
  bal_BAL_WETH: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    subLabel: '80%/20%',
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/balancer.svg', './icons/eth.svg'],
    tokenNames: ['BAL', 'ETH'],
    platform: ['Balancer'],
    tokenAddress: addresses.V2.bal_BAL_WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.bal_BAL_WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.BALANCER,
      params: [
        addresses.V2.bal_BAL_WETH.Underlying,
        addresses.V2.bal_BAL_WETH.PoolId,
        CHAIN_IDS.ETH_MAINNET,
      ],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.BALANCER,
        params: [
          addresses.V2.bal_BAL_WETH.Underlying,
          addresses.V2.bal_BAL_WETH.PoolId,
          '25750',
          profitSharingCut30Percent,
          CHAIN_IDS.ETH_MAINNET,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'BAL', 'WETH'],
  },
  UniV3_USDC_ETH: {
    inactive: true,
    isSingleAssetWithdrawalAllowed: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    subLabel: 'ETH Range: 1.9K⟷3.5K',
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/usdc.svg', './icons/eth.svg'],
    tokenNames: ['USDC', 'ETH'],
    platform: ['Uniswap V3'],
    tokenAddress: addresses.V2.UniV3_USDC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_USDC_ETH.NewVault,
    pricesInfo: {
      USDC: ['Min price: 1980 USDC per ETH', 'Max price: 3580 USDC per ETH'],
      ETH: ['Min price: 0.000279 ETH per USDC', 'Max price: 0.000505 ETH per USDC'],
    },
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_USDC_ETH.NewVault, 'USDC', 'WETH'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'USDC', 'WETH'],
  },
  UniV3_ETH_USDT: {
    inactive: true,
    isSingleAssetWithdrawalAllowed: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    subLabel: 'ETH Range: 1.9K⟷3.5K',
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/eth.svg', './icons/usdt.svg'],
    tokenNames: ['ETH', 'USDT'],
    platform: ['Uniswap V3'],
    tokenAddress: addresses.V2.UniV3_ETH_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_ETH_USDT.NewVault,
    pricesInfo: {
      ETH: ['Min price: 1980 USDT per ETH', 'Max price: 3580 USDT per ETH'],
      USDT: ['Min price: 0.000279 ETH per USDT', 'Max price: 0.000505 ETH per USDT'],
    },
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_ETH_USDT.NewVault, 'WETH', 'USDT'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WETH', 'USDT'],
  },
  UniV3_DAI_ETH: {
    inactive: true,
    isSingleAssetWithdrawalAllowed: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    subLabel: 'ETH Range: 1.9K⟷3.5K',
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/dai.svg', './icons/eth.svg'],
    tokenNames: ['DAI', 'ETH'],
    platform: ['Uniswap V3'],
    tokenAddress: addresses.V2.UniV3_DAI_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_DAI_ETH.NewVault,
    pricesInfo: {
      DAI: ['Min price: 0.000279 ETH per DAI', 'Max price: 0.000505 ETH per DAI'],
      ETH: ['Min price: 1980 DAI per ETH', 'Max price: 3580 DAI per ETH'],
    },
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_DAI_ETH.NewVault, 'DAI', 'WETH'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'DAI', 'WETH'],
  },
  UniV3_USDC_USDT: {
    inactive: true,
    isSingleAssetWithdrawalAllowed: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/usdc.svg', './icons/usdt.svg'],
    tokenNames: ['USDC', 'USDT'],
    platform: ['Uniswap V3'],
    tokenAddress: addresses.V2.UniV3_USDC_USDT.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_USDC_USDT.NewVault,
    pricesInfo: {
      USDC: ['Min price: 0.999 USDT per USDC', 'Max price: 1.001 USDT per USDC'],
      USDT: ['Min price: 0.999 USDC per USDT', 'Max price: 1.001 USDC per USDT'],
    },
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_USDC_USDT.NewVault, 'USDC', 'USDT'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'USDC', 'USDT'],
  },
  UniV3_WBTC_ETH: {
    isSingleAssetWithdrawalAllowed: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    isUniv3: true,
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/wbtc.svg', './icons/eth.svg'],
    tokenNames: ['WBTC', 'ETH'],
    platform: ['Uniswap V3'],
    tokenAddress: addresses.V2.UniV3_WBTC_ETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_WBTC_ETH.NewVault,
    pricesInfo: {
      ETH: ['Min price: 0.051 BTC per ETH', 'Max price: 0.101 BTC per ETH'],
      WBTC: ['Min price: 19.607 ETH per WBTC', 'Max price: 9.901 ETH per WBTC'],
    },
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_WBTC_ETH.NewVault, 'WBTC', 'WETH'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'WBTC', 'WETH'],
  },
  UniV3_DAI_USDC: {
    isSingleAssetWithdrawalAllowed: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/univ3.svg'],
    apyTokenSymbols: ['UNI'],
    logoUrl: ['./icons/dai.svg', './icons/usdc.svg'],
    tokenNames: ['DAI', 'USDC'],
    platform: ['Uniswap V3'],
    tokenAddress: addresses.V2.UniV3_DAI_USDC.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.UniV3_DAI_USDC.NewVault,
    pricesInfo: {
      DAI: ['Min price: 0.9994 USDC per DAI', 'Max price: 1.0024 USDC per DAI'],
      USDC: ['Min price: 0.9976 DAI per USDC', 'Max price: 1.0006 DAI per USDC'],
    },
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.UniV3_DAI_USDC.NewVault, 'DAI', 'USDC'],
    },
    estimateApyFunctions: [
      {
        extraDailyCompound: false,
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    migrationInfo: {
      lpTokenAddress: '0xAE461cA67B15dc8dc81CE7615e0320dA1A9aB8D5',
      lpTokenName: 'USDC-DAI',
      lpTokenDecimals: '18',
    },
    zapFrontrunProtection: true,
    cmcRewardTokenSymbols: ['iFARM', 'DAI', 'USDC'],
  },
  TBTC: {
    logoUrl: [],
    tokenAddress: addresses.TBTC,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.TBTC] },
  },
  '1INCH': {
    logoUrl: ['./icons/1inch.png'],
    tokenAddress: addresses['1INCH'],
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses['1INCH']] },
  },
  MUSE: {
    logoUrl: ['./icons/muse.svg'],
    tokenAddress: addresses.MUSE,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_CONTRACT,
      params: [addresses.MUSE],
    },
  },
  NUDES20: {
    logoUrl: ['./icons/nudes20.png'],
    tokenAddress: addresses.NUDES20,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.UNISWAP_PAIR,
      params: [addresses.NUDES20, addresses.WETH],
    },
  },
  MCAT20: {
    logoUrl: ['./icons/mooncat20.svg'],
    tokenAddress: addresses.MCAT20,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.UNISWAP_PAIR,
      params: [addresses.MCAT20, addresses.WETH],
    },
  },
  crvOBTC: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/curve-obtc.png'],
    tokenNames: ['OBTC', 'RENBTC', 'WBTC', 'sBTC'],
    platform: ['Curve'],
    tokenAddress: addresses.V2.crvOBTC.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.crvOBTC.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WBTC] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['obtc', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CVX', 'CRV'],
  },
  TBTCMixed: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/tbtc-mixed.png'],
    tokenNames: ['TBTC', 'RENBTC', 'WBTC', 'sBTC'],
    platform: ['Curve'],
    tokenAddress: addresses.TBTC_MIXED,
    decimals: '18',
    vaultAddress: addresses.V2.TBTCMixed.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WBTC] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['tbtc', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CRV', 'CVX'],
  },
  CRVRENWBTC: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    logoUrl: ['./icons/ren.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['RENWBTC', 'WBTC'],
    platform: ['Curve'],
    tokenAddress: addresses.CRVRENWBTC,
    decimals: '18',
    vaultAddress: addresses.V2.crvRenWBTC.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WBTC] }, // TODO: add price calculation for CRVRENWBTC
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['ren', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CVX', 'CRV'],
  },
  WBTC: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    logoUrl: ['./icons/wbtc.svg'],
    tokenNames: ['WBTC'],
    platform: ['Idle'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenAddress: addresses.WBTC,
    decimals: '8',
    vaultAddress: addresses.V2.WBTC.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.WBTC] },
    cmcRewardTokenSymbols: ['FARM', 'WBTC'],
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.IDLE_FINANCE,
        params: ['WBTC', '0x8C81121B15197fA0eEaEE1DC75533419DcfD3151', '0.7'],
      },
    ],
  },
  sETH2: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/seth.svg'],
    tokenAddress: addresses.sETH2,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_CONTRACT,
      params: [addresses.sETH2],
    },
  },
  INDEX: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/index.png'],
    tokenAddress: addresses.INDEX,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_CONTRACT,
      params: [addresses.INDEX],
    },
  },
  'ETH-MVI': {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    logoUrl: ['./icons/eth.svg', './icons/mvi.png'],
    apyIconUrls: [],
    tokenNames: ['ETH', 'MVI'],
    platform: [],
    apyTokenSymbols: [],
    tokenAddress: addresses.V2.uni_ETH_MVI.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.uni_ETH_MVI.NewVault,
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'MVI'],
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.uni_ETH_MVI.Underlying, 'WETH', 'MVI'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SNX,
        params: [
          addresses.V2.uni_ETH_MVI.RewardPool,
          'INDEX',
          'ETH-MVI',
          profitSharingCut30Percent,
        ],
      },
    ],
  },
  MVI: {
    logoUrl: ['./icons/mvi.png'],
    tokenAddress: addresses.MVI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.MVI] },
  },
  'SUSHI-SUSHI-ETH': {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    logoUrl: ['./icons/sushi.svg', './icons/eth.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH', 'SUSHI'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.V2.sushi_SUSHI_WETH.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_SUSHI_WETH.NewVault,
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'SUSHI'],
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.sushi_SUSHI_WETH.Underlying, 'SUSHI', 'WETH'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI,
        params: [SUSHI_POOLS_IDS.SUSHI, 'SUSHI', 'WETH', '0.7', null],
      },
    ],
  },
  'SUSHI-ETH-DAI': {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    logoUrl: ['./icons/eth.svg', './icons/dai.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH', 'DAI'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.sushi_DAI_WETH,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_DAI_WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_DAI_WETH, 'WETH', 'DAI'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI,
        params: [SUSHI_POOLS_IDS.DAI, 'WETH', 'DAI', '0.7', null],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'DAI'],
  },
  'SUSHI-ETH-USDT': {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    logoUrl: ['./icons/eth.svg', './icons/usdt.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH', 'USDT'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.sushi_USDT_WETH,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_USDT_WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_USDT_WETH, 'WETH', 'USDT'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI,
        params: [SUSHI_POOLS_IDS.USDT, 'WETH', 'USDT', '0.7', null],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'USDT'],
  },
  'SUSHI-ETH-WBTC': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/eth.svg', './icons/wbtc.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH', 'WBTC'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.sushi_WBTC_WETH,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_WBTC_WETH.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_WBTC_WETH, 'WETH', 'WBTC'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI,
        params: [SUSHI_POOLS_IDS.WBTC, 'WETH', 'WBTC', '0.7', null],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'WBTC'],
  },
  SUSHI_HODL: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    subLabel: 'HODL',
    logoUrl: ['./icons/sushi.svg'],
    tokenNames: ['SUSHI'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.V2.SUSHI.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.SUSHI.NewVault,
    hideiFarmSymbol: true,
    hideFarmApy: true,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.SUSHI] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.NATIVE_SUSHI,
        params: [],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'SUSHI'],
  },
  'SUSHI-ETH-DAI-HODL': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    subLabel: 'HODL',
    logoUrl: ['./icons/eth.svg', './icons/dai.svg'],
    tokenNames: ['ETH', 'DAI'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.sushi_DAI_WETH,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_DAI_WETH_HODL.NewVault,
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'DAI'],
    hideiFarmSymbol: true,
    hideFarmApy: true,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_DAI_WETH, 'WETH', 'DAI'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI_PLUS_NATIVE,
        params: [SUSHI_POOLS_IDS.DAI, 'WETH', 'DAI', '0.85', null],
        extraDailyCompound: false,
      },
    ],
  },
  'SUSHI-ETH-USDC-HODL': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    subLabel: 'HODL',
    logoUrl: ['./icons/eth.svg', './icons/usdc.svg'],
    tokenNames: ['ETH', 'USDC'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.sushi_USDC_WETH,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_USDC_WETH_HODL.NewVault,
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'USDC'],
    hideiFarmSymbol: true,
    hideFarmApy: true,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_USDC_WETH, 'WETH', 'USDC'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI_PLUS_NATIVE,
        params: [SUSHI_POOLS_IDS.USDC, 'WETH', 'USDC', '0.85', null],
        extraDailyCompound: false,
      },
    ],
  },
  'SUSHI-ETH-USDT-HODL': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    subLabel: 'HODL',
    logoUrl: ['./icons/eth.svg', './icons/usdt.svg'],
    tokenNames: ['ETH', 'USDT'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.sushi_USDT_WETH,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_USDT_WETH_HODL.NewVault,
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'USDT'],
    hideiFarmSymbol: true,
    hideFarmApy: true,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_USDT_WETH, 'WETH', 'USDT'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI_PLUS_NATIVE,
        params: [SUSHI_POOLS_IDS.USDT, 'WETH', 'USDT', '0.85', null],
        extraDailyCompound: false,
      },
    ],
  },
  'SUSHI-ETH-WBTC-HODL': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: [],
    apyTokenSymbols: [],
    subLabel: 'HODL',
    logoUrl: ['./icons/eth.svg', './icons/wbtc.svg'],
    tokenNames: ['ETH', 'WBTC'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.sushi_WBTC_WETH,
    decimals: '18',
    vaultAddress: addresses.V2.sushi_WBTC_WETH_HODL.NewVault,
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'WBTC'],
    hideiFarmSymbol: true,
    hideFarmApy: true,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_WBTC_WETH, 'WETH', 'WBTC'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI_PLUS_NATIVE,
        params: [SUSHI_POOLS_IDS.WBTC, 'WETH', 'WBTC', '0.85', null],
        extraDailyCompound: false,
      },
    ],
  },
  '1INCH-ETH-DAI': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    hodlVaultId: 'SUSHI-ETH-DAI-HODL',
    subLabel: '(migrated from 1INCH)',
    logoUrl: ['./icons/1inch.png', './icons/eth.svg', './icons/dai.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH', 'DAI'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.V2.oneInch_ETH_DAI.Underlying,
    decimals: '18',
    newDetails: true,
    vaultAddress: addresses.V2.oneInch_ETH_DAI.NewVault,
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'DAI'],
    disabledDepositTooltip: 'To deposit more assets, use vaults in the <b>SUSHI HODL</b> category',
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_DAI_WETH, 'WETH', 'DAI'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI_PLUS_NATIVE,
        params: [SUSHI_POOLS_IDS.DAI, 'WETH', 'DAI', '1', null],
        extraDailyCompound: false,
      },
    ],
  },
  '1INCH-ETH-USDC': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    hodlVaultId: 'SUSHI-ETH-USDC-HODL',
    subLabel: '(migrated from 1INCH)',
    logoUrl: ['./icons/1inch.png', './icons/eth.svg', './icons/usdc.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH', 'USDC'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.V2.oneInch_ETH_USDC.Underlying,
    decimals: '18',
    newDetails: true,
    vaultAddress: addresses.V2.oneInch_ETH_USDC.NewVault,
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'USDC'],
    disabledDepositTooltip: 'To deposit more assets, use vaults in the <b>SUSHI HODL</b> category',
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_USDC_WETH, 'WETH', 'USDC'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI_PLUS_NATIVE,
        params: [SUSHI_POOLS_IDS.USDC, 'WETH', 'USDC', '1', null],
        extraDailyCompound: false,
      },
    ],
  },
  '1INCH-ETH-USDT': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    hodlVaultId: 'SUSHI-ETH-USDT-HODL',
    subLabel: '(migrated from 1INCH)',
    logoUrl: ['./icons/1inch.png', './icons/eth.svg', './icons/usdt.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['ETH', 'USDT'],
    platform: ['Sushiswap'],
    tokenAddress: addresses.V2.oneInch_ETH_USDT.Underlying,
    decimals: '18',
    newDetails: true,
    vaultAddress: addresses.V2.oneInch_ETH_USDT.NewVault,
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'USDT'],
    disabledDepositTooltip: 'To deposit more assets, use vaults in the <b>SUSHI HODL</b> category',
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.sushi_USDT_WETH, 'WETH', 'USDT'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SUSHI_PLUS_NATIVE,
        params: [SUSHI_POOLS_IDS.USDT, 'WETH', 'USDT', '1', null],
        extraDailyCompound: false,
      },
    ],
  },
  'MUSE-ETH': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    isDegen: true,
    isNFT: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/muse.svg', './icons/eth.svg'],
    tokenNames: ['MUSE', 'ETH'],
    platform: ['Uniswap'],
    tokenAddress: addresses.V2.nft20_Muse.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.nft20_Muse.NewVault,
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'MUSE'],
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.nft20_Muse.Underlying, 'WETH', 'MUSE'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MUSE,
        params: [addresses.V2.nft20_Muse.PoolId, 'MUSE-ETH', '0'],
        extraDailyCompound: false,
      },
    ],
  },
  'DUDES20-ETH': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    isDegen: true,
    isNFT: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/dudes20.png', './icons/eth.svg'],
    tokenNames: ['DUDES20', 'ETH'],
    platform: [],
    tokenAddress: addresses.V2.nft20_Dudes.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.nft20_Dudes.NewVault,
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'NUDES20'],
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.nft20_Dudes.Underlying, 'WETH', 'NUDES20'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MUSE,
        params: [addresses.V2.nft20_Dudes.PoolId, 'DUDES20-ETH', '0'],
        extraDailyCompound: false,
      },
    ],
  },
  'MOONCAT20-ETH': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    isDegen: true,
    isNFT: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/mooncat20.svg', './icons/eth.svg'],
    tokenNames: ['MCAT20', 'ETH'],
    platform: [],
    tokenAddress: addresses.V2.nft20_Mooncat.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.nft20_Mooncat.NewVault,
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'MCAT20'],
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.nft20_Mooncat.Underlying, 'WETH', 'MCAT20'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MUSE,
        params: [addresses.V2.nft20_Mooncat.PoolId, 'MOONCAT20-ETH', '0'],
        extraDailyCompound: false,
      },
    ],
  },
  YCRV: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/ycrv.png'],
    tokenNames: ['USDC', 'USDT', 'DAI', 'TUSD'],
    platform: ['Convex Finance'],
    tokenAddress: addresses.YCRV,
    decimals: '18',
    vaultAddress: addresses.V2.YCRV.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.MANUAL, params: ['1.06'] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['ypool', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CVX', 'CRV'],
  },
  ThreePool: {
    chain: CHAIN_IDS.ETH_MAINNET,
    apyIconUrls: ['./icons/curve.svg', './icons/cvx.svg'],
    apyTokenSymbols: ['CRV', 'CVX'],
    logoUrl: ['./icons/dai.svg', './icons/usdc.svg', './icons/usdt.svg'],
    tokenNames: ['DAI', 'USDC', 'USDT'],
    tags: ['Advanced', 'Stable'],
    platform: ['Convex Finance'],
    tokenAddress: addresses.THREE_POOL,
    decimals: '18',
    vaultAddress: addresses.V2.ThreePool.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.CURVE_POOL,
      params: [addresses.V2.ThreePool.Miner, addresses.THREE_POOL, '18', ['USDC', 'USDT', 'DAI']],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['3pool', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CVX', 'CRV'],
  },
  crvCOMPOUND: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/comp.svg'],
    tokenNames: ['DAI', 'USDC'],
    platform: ['Curve'],
    tokenAddress: addresses.crvCOMPOUND,
    decimals: '18',
    vaultAddress: addresses.V2.crvCOMPOUND.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.MANUAL, params: ['1.0381'] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CONVEX,
        params: ['compound', profitSharingCut30Percent],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CVX', 'CRV'],
  },
  UNI: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/univ3.svg'],
    tokenNames: ['UNISWAP'],
    platform: ['Uniswap'],
    tokenAddress: addresses.UNI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_CONTRACT,
      params: [addresses.UNI],
    },
  },
  SUSHI: {
    logoUrl: ['./icons/sushi.svg'],
    tokenAddress: addresses.SUSHI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.SUSHI] },
  },
  DPI: {
    logoUrl: ['./icons/dpi.svg'],
    tokenAddress: addresses.DPI,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.DPI] },
  },
  CRV: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/curve.svg'],
    tokenAddress: addresses.CRV,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.CRV] },
  },
  cvxCRV: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.cvxCRV,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.cvxCRV] },
  },
  crvGUSD: {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/curve-gusd.png'],
    tokenNames: ['GUSD', 'DAI', 'USDC', 'USDT'],
    platform: ['Curve'],
    tokenAddress: addresses.V2.crvGUSD.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.crvGUSD.NewVault,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.GUSD] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.CRV_GENERAL,
        params: [
          'crvGUSD', // itself
          addresses.V2.crvGUSD.GaugePool, // gauge pool
          '0x4f062658EaAF2C1ccf8C8e36D6824CDf41167956', // swap address
          profitSharingCut30Percent,
        ],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'CRV'],
  },
  IFARM: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/ifarm.svg'],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenNames: ['iFARM'],
    platform: ['Harvest'],
    tags: ['Beginners'],
    tokenAddress: addresses.iFARM,
    decimals: '18',
    vaultAddress: addresses.iFARM,
    priceFunction: {
      type: GET_PRICE_TYPES.F_TOKEN,
      params: [addresses.iFARM, '18'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
  },
  'MEME20-ETH': {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    isDegen: true,
    isNFT: true,
    apyIconUrls: [],
    apyTokenSymbols: [],
    logoUrl: ['./icons/meme20.png', './icons/eth.svg'],
    tokenNames: ['MEME20', 'ETH'],
    platform: [],
    tokenAddress: addresses.V2.nft20_Meme.Underlying,
    decimals: '18',
    vaultAddress: addresses.V2.nft20_Meme.NewVault,
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.V2.nft20_Meme.Underlying, 'WETH', 'MEME20'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MUSE,
        params: [addresses.V2.nft20_Meme.PoolId, 'MEME20-ETH', '0'],
        extraDailyCompound: false,
      },
    ],
    cmcRewardTokenSymbols: ['iFARM', 'ETH', 'MEME20'],
  },
  MEME20: {
    logoUrl: ['./icons/meme20.png'],
    tokenAddress: addresses.MEME20,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.UNISWAP_PAIR,
      params: [addresses.MEME20, addresses.WETH],
    },
  },
  'ETH-DPI': {
    chain: CHAIN_IDS.ETH_MAINNET,
    inactive: true,
    logoUrl: ['./icons/eth.svg', './icons/dpi.svg'],
    apyIconUrls: [],
    tokenNames: ['ETH', 'DPI'],
    platform: [],
    apyTokenSymbols: [],
    tokenAddress: addresses.UNI_LP_WETH_DPI,
    decimals: '18',
    vaultAddress: addresses.VaultUNI_LP_WETH_DPI,
    cmcRewardTokenSymbols: ['FARM', 'ETH', 'DPI'],
    priceFunction: {
      type: GET_PRICE_TYPES.LP_TOKEN,
      params: [addresses.UNI_LP_WETH_DPI, 'WETH', 'DPI'],
    },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.SNX,
        params: [
          addresses.UNISWAP_SNX_POOL_ADDRESS_ETH_DPI,
          'INDEX',
          'ETH-DPI',
          profitSharingCut30Percent,
        ],
      },
    ],
  },
  RENBTC: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/ren.svg'],
    tokenNames: ['RENBTC'],
    platform: [],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenAddress: addresses.RENBTC,
    decimals: '8',
    vaultAddress: addresses.V2.renBTC.NewVault,
    hideFarmSymbol: true,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.RENBTC] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'RENBTC'],
  },
  TUSD: {
    inactive: true,
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: ['./icons/tusd.png'],
    tokenNames: ['TUSD'],
    platform: [],
    apyIconUrls: [],
    apyTokenSymbols: [],
    tokenAddress: addresses.TUSD,
    decimals: '18',
    vaultAddress: addresses.ProxiedVaultTUSD,
    priceFunction: { type: GET_PRICE_TYPES.COINGECKO_CONTRACT, params: [addresses.TUSD] },
    estimateApyFunctions: [
      {
        type: ESTIMATED_APY_TYPES.MANUAL,
        params: ['0.00'],
      },
    ],
    cmcRewardTokenSymbols: ['FARM', 'TUSD'],
  },
  fSUSHI: {
    chain: CHAIN_IDS.ETH_MAINNET,
    logoUrl: [], // unused
    tokenAddress: addresses.V2.SUSHI.NewVault,
    decimals: '18',
    vaultAddress: null,
    priceFunction: { type: GET_PRICE_TYPES.F_TOKEN, params: [addresses.V2.SUSHI.NewVault, '18'] },
  },
  JEUR: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: ['./icons/jeur.svg'],
    tokenAddress: addresses.MATIC.JEUR,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['jarvis-synthetic-euro'],
    },
  },
  pMIMO: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.pMIMO,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['mimo-parallel-governance-token'],
    },
  },
  PAR: {
    chain: CHAIN_IDS.POLYGON_MAINNET,
    logoUrl: [],
    tokenAddress: addresses.MATIC.PAR,
    decimals: '18',
    vaultAddress: null,
    priceFunction: {
      type: GET_PRICE_TYPES.COINGECKO_ID,
      params: ['par-stablecoin'],
    },
  },
}
